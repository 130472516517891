import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { AppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import '../scss/PaymentPage.scss';

const PaymentPage = () => {
  const { cart, user, setCart } = useContext(AppContext); // Ajoute setCart pour pouvoir vider le panier
  const [token, setToken] = useState(null); 
  const [isPaymentApproved, setIsPaymentApproved] = useState(false); 
  const [orderID, setOrderID] = useState(null); 
  const [payerID, setPayerID] = useState(null); 
  const navigate = useNavigate();
  const deliveryPrice = 29.0;

  const initialOptions = {
    'client-id': 'Ac97SLdS6LO5FukM_zNjliOZStmNCpRlOmeTGOMRr27zJpoULM4b65lcDV9AJaQTcSK3zeOG-YWocCct',
    currency: 'EUR',
    components: 'buttons',
    intent: 'capture',
  };

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => {
      const itemPrice = parseFloat(item.price) || 0;
      const quantity = item.quantity || 1;
      return total + itemPrice * quantity;
    }, deliveryPrice).toFixed(2);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
    console.log('Token récupéré au chargement de la page:', storedToken);
    setToken(storedToken);
  }, []);

  const onApprove = async (data, actions) => {
    return actions.order.capture().then((details) => {
      setIsPaymentApproved(true); 
      setOrderID(details.id); 
      setPayerID(details.payer.payer_id); 
    });
  };

  useEffect(() => {
    if (isPaymentApproved && token && orderID && payerID) {
      const orderData = {
        user_id: user?.user_id, 
        items: cart.map(item => ({
          id: item.id || item.product_id,
          name: item.name || item.title,
          price: item.price,
          quantity: item.quantity,
        })),
        total_price: calculateTotalPrice(),
        delivery_price: deliveryPrice,
        payerID: payerID,
        orderID: orderID,
        status: 'completed',
        is_paid: 1, 
      };

      const saveOrder = async () => {
        try {
          console.log('Données envoyées à l’API:', orderData);

          const response = await axios.post('https://npm06.com/api/orders', orderData, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.status === 201) {
            console.log('Commande enregistrée avec succès:', response.data);

            // Vider le panier après le succès de l’enregistrement
            setCart([]); // Vider le panier dans le contexte global
            localStorage.removeItem('cart'); // Vider le panier dans le localStorage

            navigate('/payment-success'); // Rediriger après le succès de l'enregistrement
          } else {
            throw new Error('Erreur lors de l’enregistrement de la commande.');
          }
        } catch (error) {
          console.error('Erreur lors de l’enregistrement de la commande:', error);
          console.error('Réponse complète de l\'API:', error.response?.data);
        }
      };

      saveOrder();
    }
  }, [isPaymentApproved, token, orderID, payerID, cart, navigate]);

  const onError = (error) => {
    alert(`Erreur avec PayPal: ${error.message}`);
    console.error('Erreur avec PayPal:', error);
  };

  return (
    <div className="payment-page">
      <div className="content-wrapper">
        <div className="recap-section">
          <h2>Récapitulatif de la commande</h2>
          {cart.length > 0 ? (
            <ul className="cart-items">
              {cart.map((item, index) => (
                <li key={index} className="cart-item">
                  <img
                    src={item.image_url ? `https://npm06.com/uploads/${item.image_url}` : '/images/fallback-image.jpg'}
                    alt={item.name}
                    className="item-image"
                  />
                  <div className="item-details">
                    <p className="item-name">{item.name}</p>
                    <p className="item-price">{parseFloat(item.price).toFixed(2)} €</p>
                    <p>Quantité: {item.quantity}</p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>Votre panier est vide.</p>
          )}
          <p>Frais de livraison : {deliveryPrice.toFixed(2)} €</p>
          <h3 className="Total_Price">Total TTC : <span className="price-red">{calculateTotalPrice()} €</span></h3>
        </div>

        <div className="payment-section">
          <h2>Paiement</h2>
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              createOrder={(data, actions) => actions.order.create({
                purchase_units: [{
                  amount: {
                    value: calculateTotalPrice(),
                  },
                }],
              })}
              onApprove={onApprove}
              onError={onError}
              style={{
                shape: 'rect',
                layout: 'vertical',
                color: 'gold',
                label: 'paypal',
              }}
            />
          </PayPalScriptProvider>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
