import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

const AdminProtectedRoute = ({ children, user }) => {
  const [loading, setLoading] = useState(true);

  // Vérification de l'authentification et du rôle d'admin
  const isAuthenticated = !!user;
  const isAdmin = user?.role === 'admin';

  useEffect(() => {
    // Arrêtez l'état de chargement si `user` est défini (même s'il est `null` ou objet)
    if (user !== undefined) {
      setLoading(false);
    }
  }, [user]);

  // Si l'application est encore en train de charger, afficher un état de chargement
  if (loading) {
    return (
      <div className="loading-container">
        <p>Chargement...</p> {/* Vous pouvez remplacer cela par un spinner */}
      </div>
    );
  }

  // Si l'utilisateur n'est pas authentifié, le rediriger vers la page de login
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Si l'utilisateur est authentifié mais n'est pas admin, redirigez vers une page d'accès refusé
  if (!isAdmin) {
    return <Navigate to="/access-denied" />;
  }

  // Si tout est correct, renvoyer le contenu protégé
  return children;
};

// Validation des props avec PropTypes
AdminProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
};

export default AdminProtectedRoute;
