import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../scss/LoginPopup.scss';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = ({ onLoginSuccess, isOpen, onClose, returnUrl }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [savePassword, setSavePassword] = useState(false);
  const [errors, setErrors] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value.trim());
    if (name === 'password') setPassword(value.trim());
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrors('');
    setIsLoading(true);

    try {
      const response = await fetch(`https://npm06.com/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        if (response.status === 401) throw new Error('Identifiants incorrects. Veuillez réessayer.');
        if (response.status === 500) throw new Error("Erreur du serveur. Veuillez réessayer plus tard.");
        throw new Error('Erreur lors de la connexion. Veuillez réessayer.');
      }

      const data = await response.json();

      // Stockage des tokens
      const storage = rememberMe ? localStorage : sessionStorage;
      storage.setItem('sessionToken', data.accessToken);
      storage.setItem('refreshToken', data.refreshToken);
      const userData = {
        user_id: data.user.user_id,
        username: data.user.username,
        usersurname: data.user.usersurname,
        email: data.user.email,
        adresse: data.user.adresse,
        code_postal: data.user.code_postal,
        ville: data.user.ville,
        pays: data.user.pays,
        phone: data.user.phone || data.user.number_phone,
        image_url: data.user.image_url || null,
        role: data.user.role,
      };

      storage.setItem('user', JSON.stringify(userData));

      if (savePassword) localStorage.setItem('savedPassword', password);

      onLoginSuccess(userData);
      setIsSuccess(true);
      setModalMessage('Connexion réussie ! Vous êtes maintenant connecté.');
      setShowModal(true);

      setTimeout(() => {
        setShowModal(false);
        onClose();
        navigate(returnUrl || '/');
      }, 2000);
    } catch (error) {
      console.error('Erreur lors de la connexion:', error.message);
      setIsSuccess(false);
      setModalMessage(error.message);
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay2">
          <div className="modal-content2">
            <span className="close-button2" onClick={onClose}>&times;</span>
            <h1>Connexion</h1>
            <p>Connectez-vous à votre compte pour continuer.</p>

            {errors && <p className="error-message">{errors}</p>}

            <form className="login-form" onSubmit={handleLogin}>
              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleChange}
                  required
                  autoComplete="username"
                />
              </div>

              <div className="form-group password-input-wrapper">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={handleChange}
                  required
                  autoComplete="current-password"
                />
                <span className="toggle-password" onClick={toggleShowPassword}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  Se souvenir de moi
                </label>
              </div>

              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={savePassword}
                    onChange={(e) => setSavePassword(e.target.checked)}
                  />
                  Sauvegarder le mot de passe
                </label>
              </div>

              <button type="submit" className="submit-button" disabled={isLoading}>
                {isLoading ? 'Connexion en cours...' : 'Se connecter'}
              </button>
              <Link to="/signup" className="signup-link">S'inscrire</Link>
            </form>
          </div>
        </div>
      )}

      {showModal && (
        <div className="popup-modal2">
          <div className={`popup-content2 ${isSuccess ? 'success' : 'error'}`}>
            <p>{modalMessage}</p>
          </div>
        </div>
      )}
    </>
  );
};

Login.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  returnUrl: PropTypes.string,
};

export default Login;
