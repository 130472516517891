import '../scss/ServiceOverview.scss';
import { Link } from 'react-router-dom'; // Importez le composant Link

const ServiceOverview = () => (
  <section id="services" className="services">
    <h2>Nos Services</h2>
    <div className="service-cards">
    <div className="service-card">
        <h3>Webmaster Fullstack</h3>
        <p>Création et gestion de sites web, développement front-end et back-end.</p>
        <Link to="/webmaster-fullstack" className="service-link">En savoir plus</Link> {/* Lien vers la page Webmaster Fullstack */}
      </div>
      <div className="service-card">
        <h3>Chanson Personnalisée</h3>
        <p>Création de chansons personnalisées pour vos événements spéciaux.</p>
        <Link to="/CustomSongService" className="service-link">En savoir plus</Link> {/* Lien vers la page Webmaster Fullstack */}
      </div>
      <div className="service-card">
        <h3>Montage Vidéo</h3>
        <p>Montage professionnel de vidéos pour divers projets, avec effets spéciaux et animations.</p>
        <Link to="/VideoEditing" className="service-link">En savoir plus</Link> {/* Lien vers la page Webmaster Fullstack */}
      </div>
      
      <div className="service-card">
  <h3>Impression 3D</h3>
  <p>Donnez forme à vos projets grâce à notre service d'impression 3D sur mesure, où l'innovation rencontre la précision pour créer des objets uniques et fonctionnels !</p>
  <Link to="/impression3dservice" className="service-link">En savoir plus</Link> 
</div>
<div className="service-card">
  <h3>Sublimation</h3>
  <p>Transformez vos idées en réalité avec notre service de sublimation personnalisée, où chaque détail prend vie sur une variété de supports uniques et créatifs !</p>
  <Link to="/sublimation-service" className="service-link">En savoir plus</Link> 
</div>
<div className="service-card">
  <h3>Événementiel</h3>
  <p>Faites de vos événements des moments inoubliables avec notre service événementiel clé en main, où chaque détail est pensé pour créer des expériences mémorables et uniques !</p>
  <Link to="/evenement" className="service-link">En savoir plus</Link> 
</div>
     
    </div>
  </section>
);

export default ServiceOverview;
