import PropTypes from 'prop-types';
import '../scss/ProductShowcase.scss';

const ProductShowcase = ({ products, serviceType }) => {
  return (
    <div className="product-showcase">
      <h2>Nos Produits de {serviceType}</h2>
      <div className="products-grid">
        {products.map((product, index) => (
          <div key={index} className="product-card">
            <img src={product.image_url} alt={product.name} />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

ProductShowcase.propTypes = {
  products: PropTypes.array.isRequired,
  serviceType: PropTypes.string.isRequired,
};

export default ProductShowcase;
