import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MyOrders from '../components/MyOrders';
import Profile from '../components/Profile';
import MessageUser from '../components/MessageUser';
import '../scss/MonCompte.scss';

const MonCompte = ({ user, setUser }) => {
  const [activeComponent, setActiveComponent] = useState('profile');
  const [userName, setUserName] = useState(user?.username || '');
  const [isLoggedIn, setIsLoggedIn] = useState(!!user);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // Charger l'utilisateur depuis le localStorage ou sessionStorage
  const getUserFromStorage = () => {
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  };

  // Charger l'utilisateur au montage du composant
  useEffect(() => {
    const storedUser = getUserFromStorage();
    console.log('Stored User:', storedUser);
    if (storedUser) {
      setUser(storedUser);
      setUserName(storedUser.username);
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    setIsLoading(false); // Fin du chargement
  }, []); // Dépendances vides pour n'exécuter qu'au montage

  // Rediriger vers la page de login si l'utilisateur n'est pas connecté
  useEffect(() => {
    if (!isLoggedIn && !isLoading) {
      navigate('/login'); // Redirection vers login si non connecté
    }
  }, [isLoggedIn, isLoading, navigate]);

  // Fonction de déconnexion
  const handleLogout = () => {
    setUser(null);
    setUserName('');
    setIsLoggedIn(false);
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
    window.location.reload(); // Recharge la page après déconnexion
  };

  // Rendu conditionnel des composants en fonction de l'onglet sélectionné
  const renderComponent = () => {
    if (!user) {
      return <div>Erreur : Utilisateur non trouvé.</div>;
    }

    switch (activeComponent) {
      case 'profile':
        return <Profile userId={user.user_id} />;
      case 'orders':
        return <MyOrders userId={user.user_id} />;
      case 'messaging':
        return <MessageUser userId={user.user_id} />;
      default:
        return <div>Onglet inconnu. Veuillez sélectionner un onglet valide.</div>;
    }
  };

  // Si en cours de chargement, afficher un spinner ou message
  if (isLoading) {
    return <div>Chargement en cours...</div>;
  }

  // Si l'utilisateur n'est pas trouvé
  if (!user) {
    return null; // Ne pas rendre le reste de la page si l'utilisateur n'est pas trouvé
  }

  return (
    <main className="mon-compte-dashboard">
      <div className="dashboard-layout">
        <aside className="sidebar-compte">
          <div className="user-info">
            <span className="welcome-message">
              <span style={{ display: 'block' }}>Bienvenue {userName}</span>
              <span style={{ display: 'block' }}>Votre Compte</span>
            </span>
          </div>
          <nav>
            <ul>
              <li onClick={() => setActiveComponent('orders')}>Mes Commandes</li>
              <li onClick={() => setActiveComponent('profile')}>Mon Profil</li>
              <li onClick={() => setActiveComponent('messaging')}>Ma Messagerie</li>
              <li>
                <button onClick={handleLogout} className="logout-button">
                  Se déconnecter
                </button>
              </li>
            </ul>
          </nav>
        </aside>
        <div className="content-area">
          {renderComponent()}
        </div>
      </div>
    </main>
  );
};

MonCompte.propTypes = {
  user: PropTypes.shape({
    user_id: PropTypes.number,
    username: PropTypes.string,
  }),
  setUser: PropTypes.func.isRequired,
};

export default MonCompte;
