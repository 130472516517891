import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo.png';
import '../scss/Header.scss';
import Login from './Login';
import CartPreview from './CartPreview';
import Navigateur from './Navigateur';

const Header = ({ user, setUser }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showCartPreview, setShowCartPreview] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showNavigateur, setShowNavigateur] = useState(false);
  const [cartCloseTimeout, setCartCloseTimeout] = useState(null);

  // Gestion de la déconnexion
  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
  };

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleClosePopup = () => {
    setShowLoginModal(false);
  };

  // Ouverture de l'aperçu du panier lors du survol
  const handleCartMouseEnter = () => {
    if (cartCloseTimeout) {
      clearTimeout(cartCloseTimeout);
    }
    setShowCartPreview(true);
  };

  // Fermeture avec un délai de 2 secondes
  const handleCartMouseLeave = () => {
    const timer = setTimeout(() => {
      setShowCartPreview(false);
    }, 2000);
    setCartCloseTimeout(timer);
  };

  useEffect(() => {
    return () => {
      if (cartCloseTimeout) clearTimeout(cartCloseTimeout);
    };
  }, [cartCloseTimeout]);

  const handleLinkClick = () => {
    setShowNavigateur(false);
  };

  // Ouverture du Navigateur au survol
  const handleMouseEnter = () => {
    if (cartCloseTimeout) {
      clearTimeout(cartCloseTimeout);
      setCartCloseTimeout(null);
    }
    setShowNavigateur(true);
  };

  // Fermeture avec délai de 3 secondes
  const handleMouseLeave = () => {
    const timer = setTimeout(() => {
      setShowNavigateur(false);
    }, 3000);
    setCartCloseTimeout(timer);
  };

  const isAdmin = user?.role === 'admin';

  return (
    <header className="header">
      <div className="top-bar">
        <div className="logo-container">
          <Link to="/ServiceOverview">
            <img src={logo} alt="NPM Run Dev Logo" className="logo" />
          </Link>
          <span className="nomsociete">NPM RUN DEV</span>
        </div>

        <div className="search-bar">
          <input type="text" placeholder="Recherche..." className="search-input" />
          <button className="search-button">🔍</button>
        </div>

        <div className="account-cart">
          {!user ? (
            <button className="account-link" onClick={handleLoginClick}>
              Se connecter / S'inscrire
            </button>
          ) : (
            <div
              className="user-info"
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
            >
              <span className="welcome-message">Bienvenue {user.username}</span>
              {showDropdown && (
                <div className="dropdown-menu">
                  <ul>
                    <li><Link to="/MonCompte">Mon Compte</Link></li>
               
                      <button onClick={handleLogout} className="logout-button">Se déconnecter</button>
                   
                  </ul>
                </div>
              )}
            </div>
          )}

          {/* Lien du panier avec l'aperçu du panier au survol */}
          <div
            className="cart-container"
            onMouseEnter={handleCartMouseEnter}
            onMouseLeave={handleCartMouseLeave}
          >
            <Link to="/cart" className="cart-link">🛒 Panier</Link>
            {showCartPreview && (
              <div
                className="cart-preview"
                onMouseEnter={handleCartMouseEnter}
                onMouseLeave={handleCartMouseLeave}
              >
                <CartPreview />
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="toggle-navigateur-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <FontAwesomeIcon
          icon={faBars}
          className="toggle-navigateur"
          size="2x"
        />
      </div>

      <div className={`navigateur-container ${showNavigateur ? 'open' : ''}`}>
        <Navigateur isAdmin={isAdmin} onLinkClick={handleLinkClick} />
      </div>

      {showLoginModal && (
        <Login
          isOpen={showLoginModal}
          onClose={handleClosePopup}
          onLoginSuccess={(userData) => {
            setUser(userData);
            setShowLoginModal(false);
          }}
        />
      )}
    </header>
  );
};

Header.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    role: PropTypes.string,
  }),
  setUser: PropTypes.func.isRequired,
};

export default Header;
