



const AdminDashboard = () => {
  return (
    
    <div> 
 
      <main className="main-content">
    
        <h1>Sélectionnez une section 
          dans la barre latérale pour commencer</h1>
    
      </main>
    </div>
  );
};

export default AdminDashboard;
