import { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../scss/AnnonceDetail.scss';
import { AppContext } from '../context/AppContext';

const AnnonceDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToCart } = useContext(AppContext);

  const [annonce, setAnnonce] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const fetchAnnonce = async () => {
      try {
        const response = await fetch(`https://www.npm06.com/api/annonces/${id}`);
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération de l\'annonce');
        }
        const data = await response.json();

        // Si les images supplémentaires existent, les inclure
        data.additional_images = Array.isArray(data.additional_images) ? data.additional_images : [];

        setAnnonce(data);

        // Définir l'image principale sélectionnée
        if (data.image_url) {
          setSelectedImage(getImageUrl(data.image_url));
        } else if (data.additional_images.length > 0) {
          setSelectedImage(getImageUrl(data.additional_images[0]));
        }
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'annonce:', error);
      }
    };

    fetchAnnonce();
  }, [id]);

  const getImageUrl = (imagePath) => {
    return imagePath.startsWith('https') || imagePath.startsWith('/')
      ? imagePath
      : `https://npm06.com/uploads/${encodeURIComponent(imagePath)}`;
  };

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  const handleMouseMove = (e) => {
    const { top, left, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPosition({ x, y });
  };

  if (!annonce) {
    return <div>Chargement...</div>;
  }

  const images = [
    annonce.image_url,
    ...annonce.additional_images,
  ].filter((image) => image && typeof image === 'string' && image.trim() !== '');

  return (
    <div className="annonce-detail-container">
      <div className="annonce-detail-left">
        <button onClick={() => navigate(-1)} className="back-button">
          ← Retour
        </button>

        {images.length > 0 && (
          <>
            <div
              className="main-image"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseMove}
            >
              <img src={getImageUrl(selectedImage)} alt="Annonce" />
              {isZoomed && (
                <div
                  className="zoom-popup"
                  style={{
                    backgroundImage: `url(${getImageUrl(selectedImage)})`,
                    backgroundPosition: `${zoomPosition.x}% ${zoomPosition.y}%`,
                    backgroundSize: '200%',
                  }}
                />
              )}
            </div>

            <div className="annonce-additional-images">
              {images.map((image, index) => (
                <div key={index} className="thumbnail-container">
                  <img
                    src={getImageUrl(image)}
                    alt={`Thumbnail ${index + 1}`}
                    className={`additional-image ${selectedImage === getImageUrl(image) ? 'selected' : ''}`}
                    onClick={() => setSelectedImage(getImageUrl(image))}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <div className="annonce-detail-right">
        <h1>{annonce.title}</h1>
        <p className="annonce-description">{annonce.description}</p>
        <p className="annonce-price">{annonce.price ? `${parseFloat(annonce.price).toFixed(2)} €` : 'Prix non spécifié'}</p>

        <button
          className="add-to-cart-button"
          onClick={() => addToCart(annonce)}
        >
          Ajouter au panier
        </button>

        <div className="annonce-extra-info">
          <h3>Détails supplémentaires</h3>
          <ul>
            <li>{annonce.detail || 'Aucun détail disponible'}</li>
            <li>{annonce.option || 'Aucune option disponible'}</li>
          </ul>
        </div>

        <div className="annonce-shipping-info">
          <h3>Informations de livraison</h3>
          <p>Livraison gratuite en France métropolitaine.</p>
        </div>
      </div>
    </div>
  );
};

export default AnnonceDetail;
