
import PropTypes from 'prop-types';
import '../scss/Popup.scss'; // Ensure you style the popup

const Popup = ({ message, onClose }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Succès</h2>
                <p>{message}</p>
                <button onClick={onClose} className="modal-close-button">OK</button>
            </div>
        </div>
    );
};

Popup.propTypes = {
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Popup;
