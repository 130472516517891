import { useState, useContext, useEffect } from 'react';
import { AppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import '../scss/CustomSongPage.scss'; // Fichier SCSS pour le style personnalisé

const CustomSongPage = () => {
  const { addToCart } = useContext(AppContext); // Utilisation du contexte pour accéder à la fonction addToCart
  const [songTitle, setSongTitle] = useState('');
  const [musicStyle, setMusicStyle] = useState('');
  const [customText, setCustomText] = useState('');
  const [songWish, setSongWish] = useState('');
  const [includeCustomText, setIncludeCustomText] = useState(false); // Choix entre texte personnalisé ou description
  const [includeClip, setIncludeClip] = useState(false);
  const [clipOption, setClipOption] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [price, setPrice] = useState(10); // Prix de base : 10€
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();

  // Tarifs mis à jour pour les options
  const basePrice = 10;
  const customTextPrice = 5; // Prix supplémentaire pour le texte personnalisé
  const clipSurprisePrice = 20;
  const clipCustomPrice = 40;

  // Mise à jour du prix en fonction des choix
  useEffect(() => {
    let calculatedPrice = basePrice;
    if (includeCustomText) {
      calculatedPrice += customTextPrice;
    }
    if (includeClip) {
      calculatedPrice += clipOption === 'Surprise' ? clipSurprisePrice : clipCustomPrice;
    }
    setPrice(calculatedPrice);
  }, [includeCustomText, includeClip, clipOption]);

  // Validation dynamique du formulaire
  useEffect(() => {
    const isValid = songTitle && musicStyle && (songWish || includeCustomText);
    setIsFormValid(isValid);
  }, [songTitle, musicStyle, songWish, includeCustomText]);

  // Gestion de l'upload de fichier avec aperçu
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setUploadedFiles(files);
  };

  const handlePreview = (file) => {
    return URL.createObjectURL(file); // Crée une URL locale pour la prévisualisation
  };

  // Ajouter la chanson personnalisée au panier
  const handleAddToCart = (e) => {
    e.preventDefault();

    const newSong = {
      product_id: Date.now(), // Utilisation d'un timestamp comme ID temporaire
      title: songTitle,
      music_style: musicStyle,
      custom_text: includeCustomText ? customText : null,
      song_wish: songWish || null,
      include_clip: includeClip ? 1 : 0,
      clip_option: clipOption || null,
      price,
      created_at: new Date(),
      updated_at: new Date(),
      uploaded_files: uploadedFiles.length ? uploadedFiles.map(file => file.name) : null, // Sauvegarde les noms des fichiers
    };

    addToCart(newSong); // Ajoute la chanson au panier en utilisant la fonction du contexte
    navigate('/cart'); // Redirige vers la page du panier
  };

  return (
    <section className="custom-song-page">
      <div className="form-container">
        <h2>🎵 Créez Votre Chanson Personnalisée 🎤</h2>
        <form onSubmit={handleAddToCart}>
          <div className="form-group">
            <label>Titre de la chanson</label>
            <input
              type="text"
              value={songTitle}
              onChange={(e) => setSongTitle(e.target.value)}
              placeholder="Entrez le titre de votre chanson"
              required
            />
          </div>

          <div className="form-group">
            <label>Style musical</label>
            <select
              value={musicStyle}
              onChange={(e) => setMusicStyle(e.target.value)}
              required
            >
              <option value="">Sélectionnez un style musical</option>
              <option value="Pop">Pop</option>
              <option value="Rock">Rock</option>
              <option value="Jazz">Jazz</option>
              <option value="Classique">Classique</option>
              <option value="Electro">Électro</option>
              <option value="Hip-hop">Hip-hop</option>
            </select>
          </div>

          {/* Choix entre texte personnalisé et description */}
          <div className="form-group">
            <label>Choisissez une option :</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="textOption"
                  value="customText"
                  checked={includeCustomText}
                  onChange={() => setIncludeCustomText(true)}
                />
                Texte personnalisé (+5€)
              </label>
              <label>
                <input
                  type="radio"
                  name="textOption"
                  value="songWish"
                  checked={!includeCustomText}
                  onChange={() => setIncludeCustomText(false)}
                />
                Description (gratuite)
              </label>
            </div>
          </div>

          {/* Champ pour le texte personnalisé */}
          {includeCustomText && (
            <div className="form-group">
              <label>Texte personnalisé</label>
              <textarea
                value={customText}
                onChange={(e) => setCustomText(e.target.value)}
                placeholder="Entrez un texte personnalisé"
              />
            </div>
          )}

          {/* Champ pour la description */}
          {!includeCustomText && (
            <div className="form-group">
              <label>Souhait pour la chanson</label>
              <textarea
                value={songWish}
                onChange={(e) => setSongWish(e.target.value)}
                placeholder="Exprimez votre souhait pour la chanson"
              />
            </div>
          )}

          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={includeClip}
                onChange={(e) => setIncludeClip(e.target.checked)}
              />
              Ajouter un clip vidéo ?
            </label>
          </div>

          {includeClip && (
            <div className="form-group">
              <label>Option de clip</label>
              <select
                value={clipOption}
                onChange={(e) => setClipOption(e.target.value)}
                required
              >
                <option value="">Sélectionnez une option de clip</option>
                <option value="Surprise">Clip surprise (+{clipSurprisePrice}€)</option>
                <option value="Personnalisé">Clip personnalisé (+{clipCustomPrice}€)</option>
              </select>
            </div>
          )}

          {clipOption === 'Personnalisé' && (
            <div className="form-group file-upload">
              <label>Uploader des fichiers (5 Mo max)</label>
              <input
                type="file"
                onChange={handleFileUpload}
                accept="image/*,video/*"
                multiple
              />
              <div className="file-preview">
                {uploadedFiles.map((file, index) => (
                  <div key={index} className="file-item">
                    <img src={handlePreview(file)} alt={file.name} />
                    <p>{file.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="form-group">
            <label>Prix total : {price}€</label>
          </div>

          <div className="form-group">
            <button type="submit" className="btn-primary" disabled={!isFormValid}>
              Ajouter au Panier
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CustomSongPage;
