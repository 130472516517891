import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate pour la redirection
import '../scss/CallToAction.scss';

const CallToAction = ({ serviceType, onClick }) => {
  const navigate = useNavigate(); // Instancier useNavigate pour rediriger l’utilisateur

  // Fonction pour rediriger vers la boutique
  const goToShop = () => {
    navigate('/shopping');
  };

  return (
    <div className="call-to-action">
      <h2>Prêt à démarrer votre projet de {serviceType} ?</h2>
      <p>Commandez dès maintenant votre {serviceType} personnalisé et transformez vos idées en réalité.</p>
      <div className="cta-buttons">
        <button className="cta-button" onClick={onClick}>Commander maintenant</button>
        <button className="shop-button" onClick={goToShop}>Aller à la boutique</button>
      </div>
    </div>
  );
};

CallToAction.propTypes = {
  serviceType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CallToAction;
