import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import ProductShowcase from './ProductShowcase';
import TestimonialSection from './TestimonialSection';
import StepsSection from './StepsSection';
import CallToAction from './CallToAction';
import axios from 'axios'; // Pour faire des requêtes HTTP
import '../scss/Impression3DServicePage.scss'; 

Modal.setAppElement('#root'); 

const Impression3DServicePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [designFile, setDesignFile] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [instructions, setInstructions] = useState(''); // Nouveau champ pour les instructions utilisateur
  const [recapVisible, setRecapVisible] = useState(false);
  const [order, setOrder] = useState(null);
  const [products3D, setProducts3D] = useState([]); // État pour les produits
  const [loading, setLoading] = useState(true); // Indicateur de chargement
  const [error, setError] = useState(null); // Gestion des erreurs

  const material = 'PLA'; // Matériau par défaut

  // Ouvrir/fermer le modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Gérer l'upload du fichier de design
  const handleDesignUpload = (e) => {
    setDesignFile(e.target.files[0]);
  };

  // Soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!designFile) {
      alert('Veuillez uploader un fichier de design.');
      return;
    }
    const formData = {
      material,
      designFile,
      quantity,
      instructions,
    };
    setOrder(formData);
    setRecapVisible(true);
  };

  // Confirmation de la commande
  const confirmOrder = () => {
    console.log('Commande confirmée :', order);
    setRecapVisible(false);
    setIsModalOpen(false);
    alert('Commande ajoutée au panier');
  };

  // Fonction pour récupérer l'URL d'une image
  const getImageUrl = (imagePath) => {
    return imagePath.startsWith('https') || imagePath.startsWith('/')
      ? imagePath
      : `https://www.npm06.com/uploads/${encodeURIComponent(imagePath)}`;
  };

  // Fonction pour récupérer les produits depuis l'API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://www.npm06.com/api/products');
        const products = response.data;

        const filteredProducts = products.filter(product => product.category_id === 1);

        const updatedProducts = filteredProducts.map(product => ({
          ...product,
          image_url: product.image_url
            ? getImageUrl(product.image_url)
            : product.additional_images && product.additional_images.length > 0
              ? getImageUrl(product.additional_images[0])
              : 'https://www.npm06.com/uploads/no-pict.jpg'
        }));

        const shuffled = updatedProducts.sort(() => 0.5 - Math.random());
        setProducts3D(shuffled.slice(0, 3));
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des produits:', error);
        setError('Erreur lors de la récupération des produits.');
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Témoignages clients
  const testimonials3D = [
    { quote: 'J\'ai fabriqué une pièce pour mon drone !', author: 'Jean Dupont' },
    { quote: 'Figurines 3D incroyables !', author: 'Marie Lefevre' },
    { quote: 'Prototypes pour notre start-up.', author: 'Sophie Martin' },
  ];

  // Étapes pour l'impression 3D
  const steps3D = [
    'Téléchargez votre modèle 3D ou choisissez parmi nos options.',
    'Personnalisez votre impression : matériau, taille, couleur.',
    'Nous imprimons votre objet avec précision.',
    'Recevez votre produit en quelques jours.',
  ];

  return (
    <div className="impression-3d-service-page">
      <header className="service-header">
        <h1>Donnez vie à vos idées avec notre service d'impression 3D !</h1>
        <p>Créez des objets uniques et sur-mesure grâce à l'impression 3D. Idéal pour des prototypes, des pièces fonctionnelles ou des créations personnalisées.</p>
      </header>

      <StepsSection steps={steps3D} serviceType="Impression 3D" />

      {loading ? (
        <p>Chargement des produits...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ProductShowcase products={products3D} serviceType="Impression 3D" />
      )}

      <TestimonialSection testimonials={testimonials3D} serviceType="Impression 3D" />
      <CallToAction serviceType="Impression 3D" onClick={toggleModal} />

      <footer className="service-footer">
        <p>Livraison rapide partout en France | Précision de haute qualité | Support client 7j/7</p>
      </footer>

      {/* Modal de personnalisation */}
      <Modal isOpen={isModalOpen} onRequestClose={toggleModal} className="order-modal" overlayClassName="modal-overlay">
        {!recapVisible ? (
          <form onSubmit={handleSubmit} className="order-form">
            <h2>Personnaliser votre impression 3D</h2>

            <label>Matériau sélectionné :</label>
            <p><strong>PLA</strong> - Le PLA (acide polylactique) est un matériau thermoplastique biosourcé, idéal pour les impressions 3D de haute qualité. Il est biodégradable, économique et offre une belle finition lisse.</p>

            <label htmlFor="instructions">Instructions supplémentaires :</label>
            <textarea
              id="instructions"
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
              placeholder="Ajoutez des instructions spécifiques pour votre impression..."
              rows="4"
            />

            <label htmlFor="designFile">Uploader votre fichier de design 3D (STL, OBJ, etc.) :</label>
            <input type="file" id="designFile" accept=".stl, .obj" onChange={handleDesignUpload} required />

            <label htmlFor="quantity">Quantité :</label>
            <input
              type="number"
              id="quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              min="1"
              max="100"
              required
            />

            <button type="submit" className="submit-button">
              Valider
            </button>
          </form>
        ) : (
          <div className="order-recap">
            <h2>Récapitulatif de la commande</h2>
            <p><strong>Matériau :</strong> {order.material}</p>
            <p><strong>Instructions :</strong> {order.instructions}</p>
            <p><strong>Fichier de design :</strong> {order.designFile?.name}</p>
            <p><strong>Quantité :</strong> {order.quantity}</p>

            <button onClick={confirmOrder} className="confirm-button">
              Confirmer la commande
            </button>
            <button onClick={() => setRecapVisible(false)} className="edit-button">
              Modifier la commande
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Impression3DServicePage;
