import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../scss/SidebarCat.scss';

const SidebarCat = ({ isOpen = true, onClose = () => {}, onSelectCategory, allowedCategories = [] }) => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingSubcategories, setLoadingSubcategories] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://www.npm06.com/api/categories');
        const filteredCategories = response.data.filter((category) =>
          allowedCategories.includes(category.id)
        );
        setCategories(filteredCategories);
      } catch (error) {
        setError('Impossible de récupérer les catégories.');
        console.error('Erreur lors de la récupération des catégories:', error);
      } finally {
        setLoadingCategories(false);
      }
    };

    fetchCategories();
  }, [allowedCategories]);

  const fetchSubcategories = async (categoryId) => {
    setLoadingSubcategories(categoryId);
    try {
      const response = await axios.get(`https://www.npm06.com/api/subcategories/category/${categoryId}`);
      
      if (response.status === 200 && response.data) {
        setSubcategories((prev) => ({ ...prev, [categoryId]: response.data }));
        setError(null);
      } else {
        throw new Error(`Erreur ${response.status} : ${response.data?.message || "Erreur inconnue."}`);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des sous-catégories:', error);
      setError(`Impossible de récupérer les sous-catégories pour la catégorie ${categoryId}.`);
    } finally {
      setLoadingSubcategories(null);
    }
  };

  const handleCategoryHover = (categoryId) => {
    setSelectedCategoryId(categoryId);
    if (!subcategories[categoryId]) {
      fetchSubcategories(categoryId);
    }
  };

  const handleSubcategoryClick = (subcategory) => {
    if (onSelectCategory) {
      onSelectCategory(subcategory); // Transmettre la sous-catégorie sélectionnée au parent
    }
    onClose();
  };

  return (
    <div className={`sidebar-cat ${isOpen ? 'open' : 'closed'}`}>
 {/* Barre latérale - entête */}
{/* Remarque : le bouton de fermeture est temporairement commenté */}
<div className="sidebar-header">
    {/* <button className="close-btn" onClick={onClose}>×</button> */}
</div>


      <ul className="sidebar-links">
        {loadingCategories ? (
          <p>Chargement des catégories...</p>
        ) : categories.length > 0 ? (
          categories.map((category) => (
            <li 
              key={category.id} 
              className="category-item"
              onMouseEnter={() => handleCategoryHover(category.id)}
              onMouseLeave={() => setSelectedCategoryId(null)}
            >
              <button>{category.name}</button>

              {selectedCategoryId === category.id && (
                <ul className="subcategory-list">
                  {loadingSubcategories === category.id ? (
                    <p>Chargement des sous-catégories...</p>
                  ) : subcategories[category.id] && subcategories[category.id].length > 0 ? (
                    subcategories[category.id].map((subcategory) => (
                      <li
                        key={subcategory.id}
                        className="subcategory-item"
                        onClick={() => handleSubcategoryClick(subcategory)}
                      >
                        {subcategory.name}
                      </li>
                    ))
                  ) : (
                    <li className="subcategory-item">Aucune sous-catégorie disponible</li>
                  )}
                </ul>
              )}
            </li>
          ))
        ) : (
          <p>Aucune catégorie disponible.</p>
        )}
      </ul>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

SidebarCat.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSelectCategory: PropTypes.func,
  allowedCategories: PropTypes.arrayOf(PropTypes.number),
};

export default SidebarCat;
