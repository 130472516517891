import { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../scss/YouTubeDetail.scss';
import { AppContext } from '../context/AppContext';
import { FaTrashAlt } from 'react-icons/fa';

const YouTubeDetail = () => {
  const { id } = useParams(); // Récupérer l'ID de la vidéo depuis l'URL
  const navigate = useNavigate();
  const { addToFavorites, removeFromFavorites } = useContext(AppContext); 

  const [video, setVideo] = useState(null); // Détail de la vidéo
  const [errorMessage, setErrorMessage] = useState(null); 

  useEffect(() => {
    // Récupération de la vidéo à partir de l'API en utilisant l'`id`
    fetch(`https://npm06.com/api/youtubevideos/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération de la vidéo');
        }
        return response.json();
      })
      .then((data) => {
        setVideo(data);
      })
      .catch((error) => {
        console.error('Erreur:', error);
        setErrorMessage('Erreur lors de la récupération des détails de la vidéo.');
      });
  }, [id]);

  if (!video) {
    return errorMessage ? <div>{errorMessage}</div> : <div>Chargement...</div>;
  }

  const handleAddToFavorites = () => {
    addToFavorites(video);
  };

  const handleRemoveFromFavorites = () => {
    removeFromFavorites(video);
  };

  // Utilisation de la bonne clé pour intégrer la vidéo YouTube
  const getYouTubeEmbedUrl = (video_key) => {
    return `https://www.youtube.com/embed/${video_key}`;
  };

  return (
    <div className="youtube-detail-container">
      <div className="youtube-detail-left">
        <button onClick={() => navigate(-1)} className="back-button">
          ← Retour
        </button>

        {/* Affichage de la vidéo YouTube */}
        <div className="youtube-video">
          <iframe
            width="560"
            height="315"
            src={getYouTubeEmbedUrl(video.video_key)} 
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="youtube-detail-right">
        <h1>{video.title}</h1>
        <div className="youtube-rating">
          <span>★★★★★</span> {/* Exemple de notation */}
          <span>(10 avis)</span>
        </div>
        <p className="youtube-description">{video.description}</p>
      </div>

      <div className="youtube-detail-actions">
        <button onClick={handleAddToFavorites} className="add-to-favorites-button">
          Ajouter aux favoris
        </button>

        <button onClick={handleRemoveFromFavorites} className="remove-from-favorites-button">
          <FaTrashAlt /> Retirer des favoris
        </button>
      </div>

      <div className="youtube-extra-info">
        <h3>Détails supplémentaires</h3>
        <ul>
          <li>ID de la vidéo: {video.video_id}</li>
          <li>Date de publication: {new Date(video.createdAt).toLocaleDateString()}</li>
        </ul>
      </div>
    </div>
  );
};

export default YouTubeDetail;
