import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../scss/SearchBar.scss'; // Assurez-vous de créer un fichier SCSS pour styliser la barre de recherche

const SearchBar = ({ placeholder, onSearch }) => {
  const [query, setQuery] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    onSearch(value); // On envoie la valeur à la fonction de recherche parent
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder={placeholder || 'Rechercher...'}
        className="search-input"
      />
    </div>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired, // Fonction à exécuter à chaque modification du champ de recherche
};

export default SearchBar;
