import { useState, useEffect, useContext } from 'react';
import '../scss/Contact.scss';
import Login from './Login';
import { AppContext } from '../context/AppContext';

function ContactForm() {
  const { isLoggedIn, setIsLoggedIn, user } = useContext(AppContext);  // Utilisation du contexte pour récupérer l'utilisateur
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');  
  const [followUp, setFollowUp] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false); 

  // Vérifier si l'utilisateur est déjà connecté lors du chargement du composant
  useEffect(() => {
    const token = localStorage.getItem('userToken') || sessionStorage.getItem('userToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn]);

  // Pré-remplir les champs si l'utilisateur est connecté
  useEffect(() => {
    if (isLoggedIn && user) {
      setName(user.username || '');
      setEmail(user.email || '');
      setPhone(user.phone || ''); 
    }
  }, [isLoggedIn, user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    // Si le suivi est demandé, l'utilisateur doit être connecté
    if (followUp && !isLoggedIn) {
      setShowLogin(true);  
      setFormSubmitted(true);  
      return;
    }

    // Inclure l'ID de l'utilisateur uniquement si connecté et suivi activé
    const formData = {
      name,
      email,
      phone,
      subject,
      message, 
      follow_up: followUp ? 1 : 0,  
      user_id: followUp && user ? user.user_id : null,  
    };

    try {
      const headers = {
        'Content-Type': 'application/json',
      };

      // Ajouter l'en-tête Authorization seulement si le suivi est demandé et que l'utilisateur est connecté
      if (followUp && isLoggedIn) {
        const token = localStorage.getItem('userToken') || sessionStorage.getItem('userToken');
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }
      }

      const response = await fetch(`https://npm06.com/api/contactmessages`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors) {
          throw new Error(errorData.errors.map((err) => err.msg).join(', '));
        }
        throw new Error('Erreur lors de l\'envoi du formulaire.');
      }

      setSuccess(true);  
      setFormSubmitted(false);  
    } catch (error) {
      console.error('Erreur lors de l\'envoi du formulaire:', error.message);  
      setError(error.message);  
    }
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    setShowLogin(false);  
    if (formSubmitted) {
      handleSubmit(new Event('submit'));  
    }
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  return (
    <div className="contact-page">
      <div className="contact-container">
        <div className="contact-info">
          <h2>Adresse</h2>
          <p>251 avenue de la porte des Alpes</p>
          <p>06670 Levens</p>
          <h2>Parlons-en</h2>
          <p>+33 645424233</p>
          <h2>Support général</h2>
          <p>contact@npm06.fr</p>
        </div>

        <div className="contact-form">
          <h1>Votre Message</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Nom</label>
              <input
                type="text"
                placeholder="Votre nom"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                placeholder="Eg. example@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label>Téléphone</label>
              <input
                type="text"
                placeholder="Eg. +33 645424233"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Sujet</label>
              <input
                type="text"
                placeholder="Objet de votre message"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Message</label>
              <textarea
                placeholder="Votre message"
                value={message}  
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>

            <div className="form-group follow-up">
              <label>
                <input
                  type="checkbox"
                  checked={followUp}
                  onChange={() => setFollowUp(!followUp)}
                />
                Je souhaite recevoir un suivi
              </label>
            </div>

            <button type="submit" className="submit-button">Envoyer</button>

            {error && <p className="error">{error}</p>}
            {success && <p className="success">Message envoyé avec succès !</p>}
          </form>
        </div>
      </div>

      {showLogin && (
        <Login
          isOpen={showLogin}
          onClose={handleCloseLogin}
          onLoginSuccess={handleLoginSuccess}
          returnUrl="/contact"
        />
      )}
    </div>
  );
}

export default ContactForm;
