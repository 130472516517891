import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../AdminScss/messagesManager.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config/config';

function MessagesManager() {
  const [messages, setMessages] = useState([]);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [currentMessageId, setCurrentMessageId] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const messagesPerPage = 15;
  const navigate = useNavigate();

  // Récupérer le token depuis localStorage ou sessionStorage
  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  // Charger les messages lors de la première monture du composant
  useEffect(() => {
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const token = getToken();

    // Vérifier que l'utilisateur est administrateur
    if (!parsedUser || parsedUser.role !== 'admin' || !token) {
      toast.error("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    } else {
      fetchMessages(token);
    }
  }, [navigate]);

  // Fonction pour récupérer les messages
  const fetchMessages = async (token) => {
    setLoading(true);
    try {
      const response = await axios.get('https://www.npm06.com/api/contactmessages/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data && Array.isArray(response.data.rows)) {
        setMessages(response.data.rows);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des messages:', error);
      toast.error("Erreur lors de la récupération des messages.");
    } finally {
      setLoading(false);
    }
  };

  // Gestion de la sélection/désélection d'un message
  const handleCheckboxChange = (messageId) => {
    setSelectedMessages((prevState) =>
      prevState.includes(messageId)
        ? prevState.filter((id) => id !== messageId)
        : [...prevState, messageId]
    );
  };

  // Sélectionner/Désélectionner tous les messages
  const handleSelectAllChange = () => {
    if (selectedMessages.length === messages.length) {
      setSelectedMessages([]);
    } else {
      setSelectedMessages(messages.map((message) => message.message_id));
    }
  };

  // Suppression des messages sélectionnés
  const handleDeleteSelectedMessages = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer les messages sélectionnés ?')) {
      try {
        setLoading(true);
        const token = getToken();
        await Promise.all(
          selectedMessages.map(async (messageId) => {
            await axios.delete(`${config}/api/contactmessages/${messageId}`, {
              headers: { Authorization: `Bearer ${token}` },
            });
          })
        );
        setMessages((prevState) =>
          prevState.filter((message) => !selectedMessages.includes(message.message_id))
        );
        setSelectedMessages([]);
        toast.success("Messages supprimés avec succès.");
      } catch (error) {
        console.error('Erreur lors de la suppression des messages:', error);
        toast.error("Erreur lors de la suppression des messages.");
      } finally {
        setLoading(false);
      }
    }
  };

  // Ouvrir la fenêtre de réponse
  const handleReply = (messageId) => {
    const message = messages.find((msg) => msg.message_id === messageId);
    if (message) {
      setCurrentMessageId(messageId);
      setReplyContent(message.reponse || '');
      setIsModalOpen(true);
    }
  };

  // Fonction pour envoyer une réponse au message (corrigée et utilisée)
  const handleSendReply = async () => {
    if (currentMessageId && replyContent.trim() !== '') {
      try {
        const token = getToken();
  
        // Récupérer l'utilisateur stocké dans le localStorage/sessionStorage
        const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
        const user = storedUser ? JSON.parse(storedUser) : null;
  
        if (!user || !user.user_id) {
          toast.error("Utilisateur non authentifié. Impossible d'envoyer la réponse.");
          return;
        }
  
        const response = await axios.post(
          `${config}/api/contactmessages/${currentMessageId}/response`,
          {
            response_text: replyContent.trim(),
            response_user_id: user.user_id,  // Utilisation du user_id de l'utilisateur connecté
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
  
        if (response.status === 201) {
          const newResponse = response.data.result;
          
          // Mise à jour des messages avec la nouvelle réponse et changement de couleur pour l'affichage
          const updatedMessages = messages.map((msg) => {
            if (msg.message_id === currentMessageId) {
              return {
                ...msg,
                responses: msg.responses ? [...msg.responses, newResponse] : [newResponse],
                repondu: true  // Marque que le message a été répondu
              };
            }
            return msg;
          });
  
          setMessages(updatedMessages);
          setReplyContent('');  // Réinitialise le contenu du champ réponse
          setIsModalOpen(false);  // Ferme le modal
          toast.success("Réponse envoyée avec succès.");
        }
      } catch (err) {
        console.error('Erreur lors de l\'envoi de la réponse:', err);
        toast.error("Erreur lors de l'envoi de la réponse.");
      }
    }
  };
  
  
  // Suppression d'un message individuel
  const handleDeleteMessage = async (messageId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce message ?')) {
      try {
        const token = getToken();
        await axios.delete(`${config}/api/contactmessages/${messageId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setMessages((prevState) => prevState.filter((message) => message.message_id !== messageId));
        toast.success("Message supprimé avec succès.");
      } catch (error) {
        console.error('Erreur lors de la suppression du message:', error);
        toast.error("Erreur lors de la suppression du message.");
      }
    }
  };

  // Affichage des détails d'un message
  const handleViewDetails = (messageId) => {
    const message = messages.find((msg) => msg.message_id === messageId);
    setCurrentMessage(message);
    setIsModalOpen(true);
  };

  // Gestion des couleurs pour les messages sans réponse
  const getRowStyle = (message) => {
    const now = new Date();
    const createdAt = new Date(message.created_at);
    const diffHours = Math.abs(now - createdAt) / 36e5; // Différence en heures
    if (!message.response_text) {
      if (diffHours > 72) return { backgroundColor: 'red' };
      if (diffHours > 48) return { backgroundColor: 'orange' };
    }
    return {};
  };

  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = Array.isArray(messages)
    ? messages.slice(indexOfFirstMessage, indexOfLastMessage)
    : [];

  const totalPages = Math.ceil(messages.length / messagesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="messages-manager-container">
      <h1>Gestion des Messages</h1>
      <button
        onClick={handleDeleteSelectedMessages}
        disabled={selectedMessages.length === 0 || loading}
      >
        {loading ? "Suppression..." : "Supprimer les messages sélectionnés"}
      </button>
      {loading ? (
        <p>Chargement des messages...</p>
      ) : (
        <>
          <table className="table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectedMessages.length === messages.length && messages.length > 0}
                    onChange={handleSelectAllChange}
                  />
                </th>
                <th>Nom Utilisateur</th>
                <th>Objet</th>
                <th>Date de création</th>
                <th>Date de réponse</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentMessages.length > 0 ? (
                currentMessages.map((message) => (
                  <tr key={message.message_id} style={getRowStyle(message)}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedMessages.includes(message.message_id)}
                        onChange={() => handleCheckboxChange(message.message_id)}
                      />
                    </td>
                    <td>{message.name}</td>
                    <td>{message.subject}</td>
                    <td>{new Date(message.created_at).toLocaleDateString()}</td>
                    <td>
                      {message.response_created_at
                        ? new Date(message.response_created_at).toLocaleDateString()
                        : 'Pas encore de réponse'}
                    </td>
                    <td>
                      <button onClick={() => handleViewDetails(message.message_id)}>
                        Voir Détail
                      </button>
                      <button onClick={() => handleReply(message.message_id)}>Répondre</button>
                      <button onClick={() => handleDeleteMessage(message.message_id)}>
                        Supprimer
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">Aucun message disponible</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={index + 1 === currentPage ? 'active' : ''}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}
      {isModalOpen && currentMessage && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={() => setIsModalOpen(false)}>
              &times;
            </span>
            <h2>Détails du message</h2>
            <p><strong>Nom Utilisateur :</strong> {currentMessage.name}</p>
            <p><strong>Objet :</strong> {currentMessage.subject}</p>
            <p><strong>Message :</strong></p>
            <p>{currentMessage.message_text}</p>
            {currentMessage.responses && (
              <>
                <p><strong>Réponses précédentes :</strong></p>
                {currentMessage.responses.map((response, index) => (
                  <p key={index}>{response.response}</p>
                ))}
              </>
            )}
            <textarea
              value={replyContent}
              onChange={(e) => setReplyContent(e.target.value)}
              placeholder="Entrez votre réponse ici..."
              rows="6"
              required
            ></textarea>
            <button onClick={handleSendReply}>Envoyer la réponse</button>
          </div>
        </div>
      )}
    </div>
  );
}

MessagesManager.propTypes = {
  replyToMessages: PropTypes.func,
};

export default MessagesManager;
