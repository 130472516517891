import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../scss/Signup.scss';
import Popup from './Popup'; // Assurez-vous d'avoir un composant Popup

const Signup = () => {
    const [formData, setFormData] = useState({
        username: '',
        usersurname: '',
        email: '',
        password: '',
        passwordConfirm: '',
        adresse: '',
        ville: '',
        code_postal: '',
        pays: '',
        number_phone: '',
        image_url: '', // This will store either the image_url URL or File object
    });

    const [errors, setErrors] = useState({});
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const navigate = useNavigate();
    const [previewImage, setPreviewImage] = useState(null);

    // Fonction pour gérer les changements dans le formulaire
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Fonction pour gérer le changement d'image
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({ ...formData, image_url: file });
            setPreviewImage(URL.createObjectURL(file));
        }
    };

    // Fonction pour annuler l'image sélectionnée
    const handleCancelImage = () => {
        setFormData({ ...formData, image_url: '' });
        setPreviewImage(null);
    };

    // Fonction pour réinitialiser le formulaire
    const handleReset = () => {
        setFormData({
            username: '',
            usersurname: '',
            email: '',
            password: '',
            passwordConfirm: '',
            adresse: '',
            ville: '',
            code_postal: '',
            pays: '',
            number_phone: '',
            image_url: '',
        });
        setErrors({});
        setPreviewImage(null);
    };

    // Fonction pour gérer la soumission du formulaire
    const handleSave = async (e) => {
        e.preventDefault();
        setErrors({});

        if (formData.password !== formData.passwordConfirm) {
            setErrors({ passwordConfirm: "Les mots de passe ne correspondent pas" });
            return;
        }

        // Regex pour la validation du mot de passe
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!passwordRegex.test(formData.password)) {
            setErrors({ password: "Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial." });
            return;
        }

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('username', formData.username);
            formDataToSend.append('usersurname', formData.usersurname);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('password', formData.password);
            formDataToSend.append('adresse', formData.adresse);
            formDataToSend.append('ville', formData.ville);
            formDataToSend.append('code_postal', formData.code_postal);
            formDataToSend.append('pays', formData.pays);
            formDataToSend.append('number_phone', formData.number_phone);

            if (formData.image_url && formData.image_url instanceof File) {
                formDataToSend.append('image_url', formData.image_url);
            }

            const response = await fetch('https://npm06.com/api/users/register', {
                method: 'POST',
                body: formDataToSend,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la création de l\'utilisateur');
            }

            // Afficher le popup et rediriger après un délai
            setIsPopupVisible(true);
            setTimeout(() => {
                setIsPopupVisible(false);
                navigate('/login'); // Redirection vers la page de login après inscription réussie
            }, 2000); // Redirection après 2 secondes

        } catch (error) {
            console.error('Erreur:', error);
            setErrors({ form: error.message });
        }
    };

    return (
        <div className="signup-page">
            <h1>Inscrivez-vous</h1>
            <p>Créez un compte pour accéder à nos services.</p>

            {errors.form && <p className="error-message">{errors.form}</p>}

            <form className="signup-form" onSubmit={handleSave}>
                <div className="form-row">
                    <div className="form-group">
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Prénom"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="usersurname"
                            name="usersurname"
                            placeholder="Nom"
                            value={formData.usersurname}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="number_phone"
                            name="number_phone"
                            placeholder="Numéro de téléphone"
                            value={formData.number_phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Mot de passe"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                        {errors.password && <p className="error-message">{errors.password}</p>}
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            id="passwordConfirm"
                            name="passwordConfirm"
                            placeholder="Confirmez le mot de passe"
                            value={formData.passwordConfirm}
                            onChange={handleChange}
                            required
                        />
                        {errors.passwordConfirm && <p className="error-message">{errors.passwordConfirm}</p>}
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <input
                            type="text"
                            id="adresse"
                            name="adresse"
                            placeholder="Adresse"
                            value={formData.adresse}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="code_postal"
                            name="code_postal"
                            placeholder="Code postal"
                            value={formData.code_postal}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <input
                            type="text"
                            id="ville"
                            name="ville"
                            placeholder="Ville"
                            value={formData.ville}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="pays"
                            name="pays"
                            placeholder="Pays"
                            value={formData.pays}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                {/* Field for image_url file upload */}
                <div className="form-row">
                    <div className="form-group">
                        <input
                            type="file"
                            id="image_url"
                            name="image_url"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                        {previewImage && (
                            <div className="image-preview">
                                <img src={previewImage} alt="Prévisualisation de l'image_url" style={{ width: '100px', height: '100px' }} />
                                <button type="button" onClick={handleCancelImage}>Annuler l'image</button>
                            </div>
                        )}
                    </div>
                </div>

                <button type="submit" className="submit-button">S'inscrire</button>
                <button type="button" className="reset-button" onClick={handleReset}>Effacer</button>
            </form>

            {isPopupVisible && <Popup message="Inscription réussie !" />}
        </div>
    );
};

export default Signup;
