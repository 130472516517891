// axiosInstance.js
import axios from 'axios';

const token = localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
console.log('Token:', token);

const axiosInstance = axios.create({
  baseURL: 'https://npm06.com/api',
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export default axiosInstance;
