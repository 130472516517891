import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance'; // Instance Axios pour les appels API
import SearchBar from '../widgets/SearchBar';
import '../AdminScss/UserManager.scss';

const UserManager = () => {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    username: '',
    usersurname: '',
    email: '',
    password: '',
    adresse: '',
    code_postal: '',
    ville: '',
    pays: '',
    number_phone: '',
    role: 'user',
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  // Fonction pour récupérer le token depuis le localStorage ou le sessionStorage
  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  useEffect(() => {
    const sessionToken = getToken();
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;

    if (!parsedUser || parsedUser.role !== 'admin' || !sessionToken) {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    } else {
      setToken(sessionToken);
    }
  }, [navigate]);

  // Récupération des utilisateurs
  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/users', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs:", error);
      setErrorMessage("Erreur lors de la récupération des utilisateurs.");
    } finally {
      setLoading(false);
    }
  }, [token]);

  // Charger les utilisateurs au montage du composant
  useEffect(() => {
    if (token) fetchUsers();
  }, [token, fetchUsers]);

  // Gestion des changements dans le formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Enregistrer ou mettre à jour un utilisateur
  const handleSave = async (e) => {
    e.preventDefault();
    const method = selectedUser ? 'put' : 'post';
    const url = selectedUser ? `/users/${selectedUser.user_id}` : '/users';

    try {
      await axiosInstance({
        method,
        url,
        headers: { Authorization: `Bearer ${token}` },
        data: { ...formData, password: formData.password || undefined },
      });
      fetchUsers(); // Rafraîchir la liste des utilisateurs
      closeModal();
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'utilisateur:", error);
      setErrorMessage("Erreur lors de l'enregistrement de l'utilisateur.");
    }
  };

  // Supprimer un utilisateur
  const handleDeleteUser = async (id) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) return;

    try {
      await axiosInstance.delete(`/users/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchUsers(); // Rafraîchir la liste des utilisateurs
    } catch (error) {
      console.error("Erreur lors de la suppression de l'utilisateur:", error);
      setErrorMessage("Erreur lors de la suppression de l'utilisateur.");
    }
  };

  // Ouvrir la modal pour ajouter ou modifier un utilisateur
  const openModal = (user = null) => {
    setSelectedUser(user);
    setFormData({
      username: user?.username || '',
      usersurname: user?.usersurname || '',
      email: user?.email || '',
      password: '',
      adresse: user?.adresse || '',
      code_postal: user?.code_postal || '',
      ville: user?.ville || '',
      pays: user?.pays || '',
      number_phone: user?.number_phone || '',
      role: user?.role || 'user',
    });
    setIsModalOpen(true);
  };

  // Fermer la modal
  const closeModal = () => {
    setSelectedUser(null);
    setFormData({
      username: '',
      usersurname: '',
      email: '',
      password: '',
      adresse: '',
      code_postal: '',
      ville: '',
      pays: '',
      number_phone: '',
      role: 'user',
    });
    setIsModalOpen(false);
  };

  return (
    <div className="user-manager-container">
      <h1>Gestion des utilisateurs</h1>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {loading && <p>Chargement...</p>}
      <SearchBar
        data={users}
        onSearch={(filtered) => setUsers(filtered)}
        searchFields={['username', 'usersurname', 'email']}
      />
      <button className="add-user-button" onClick={() => openModal()}>Ajouter un utilisateur</button>
      <table className="user-table">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Email</th>
            <th>Téléphone</th>
            <th>Adresse</th>
            <th>Rôle</th>
            <th>Date de création</th>
            <th>Date de mise à jour</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            users.map((user) => (
              <tr key={user.user_id}>
                <td>{user.username} {user.usersurname}</td>
                <td>{user.email}</td>
                <td>{user.number_phone}</td>
                <td>{`${user.adresse}, ${user.code_postal}, ${user.ville}, ${user.pays}`}</td>
                <td>{user.role}</td>
                <td>{new Date(user.created_at).toLocaleDateString()}</td>
                <td>{new Date(user.updated_at).toLocaleDateString()}</td>
                <td>
                  <button onClick={() => openModal(user)}>Modifier</button>
                  <button onClick={() => handleDeleteUser(user.user_id)}>Supprimer</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">Aucun utilisateur trouvé</td>
            </tr>
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <form onSubmit={handleSave}>
              <input type="text" name="username" value={formData.username} onChange={handleInputChange} placeholder="Prénom" required />
              <input type="text" name="usersurname" value={formData.usersurname} onChange={handleInputChange} placeholder="Nom" required />
              <input type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Email" required />
              <input type="password" name="password" value={formData.password} onChange={handleInputChange} placeholder="Mot de passe" required={!selectedUser} />
              <input type="text" name="adresse" value={formData.adresse} onChange={handleInputChange} placeholder="Adresse" required />
              <input type="text" name="code_postal" value={formData.code_postal} onChange={handleInputChange} placeholder="Code postal" required />
              <input type="text" name="ville" value={formData.ville} onChange={handleInputChange} placeholder="Ville" required />
              <input type="text" name="pays" value={formData.pays} onChange={handleInputChange} placeholder="Pays" required />
              <input type="text" name="number_phone" value={formData.number_phone} onChange={handleInputChange} placeholder="Téléphone" required />
              <select name="role" value={formData.role} onChange={handleInputChange}>
                <option value="user">Utilisateur</option>
                <option value="admin">Administrateur</option>
              </select>
              <button type="submit">{selectedUser ? 'Mettre à jour' : 'Ajouter'}</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManager;
