import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter'; // Pour exporter en STL
import { saveAs } from 'file-saver'; // Pour sauvegarder le fichier STL
import Modal from 'react-modal';
import Picker from '@emoji-mart/react';
import '../scss/Text3DModal.scss'; // SCSS correspondant

Modal.setAppElement('#root');

const Text3DModal = ({ isOpen, onClose }) => {
  const mountRef = useRef(null);
  const [font, setFont] = useState(null);
  const [size, setSize] = useState(45);
  const [depth, setDepth] = useState(10);
  const [text, setText] = useState('Hello 3D!');
  const [emojiLeft, setEmojiLeft] = useState(''); // Emoticône à gauche
  const [emojiRight, setEmojiRight] = useState(''); // Emoticône à droite
  const [scene, setScene] = useState(null); // Sauvegarder la scène pour l'export
  const [pickerOpen, setPickerOpen] = useState(false); // Contrôle de l'ouverture du Picker d'emoji

  // Charger la police en 3D
  useEffect(() => {
    const loader = new FontLoader();
    loader.load('/fonts/helvetiker_regular.typeface.json', (loadedFont) => {
      setFont(loadedFont);
    });
  }, []);

  // Initialiser la scène et les objets 3D
  useEffect(() => {
    if (font && mountRef.current && isOpen) {
      const currentMount = mountRef.current;

      // Configuration de Three.js
      const scene = new THREE.Scene();
      setScene(scene); // Sauvegarder la scène pour l'export
      const camera = new THREE.PerspectiveCamera(75, currentMount.clientWidth / currentMount.clientHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);
      currentMount.appendChild(renderer.domElement);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true; // Pour des mouvements de caméra fluides

      // Créer le texte 3D
      const textGeometry = new TextGeometry(text, {
        font: font,
        size: size,
        depth: depth,
      });

      // Centrer la géométrie du texte
      textGeometry.computeBoundingBox();
      const textWidth = textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x;
      const textHeight = textGeometry.boundingBox.max.y - textGeometry.boundingBox.min.y;

      const material = new THREE.MeshBasicMaterial({ color: 0xffa500 });
      const textMesh = new THREE.Mesh(textGeometry, material);
      textMesh.position.set(-textWidth / 2, -26, 0); // Centrer le texte

      scene.add(textMesh);

      // Créer la base (support rectangulaire) plus large
      const baseWidth = textWidth + 60; // Ajouter 60 unités de marge à gauche et à droite
      const baseHeight = 1.5; // Hauteur du support
      const baseDepth = 50; // Profondeur identique à celle du texte

      const baseGeometry = new THREE.BoxGeometry(baseWidth, baseHeight, baseDepth); // Support ajusté à la taille du texte
      const baseMaterial = new THREE.MeshBasicMaterial({ color: 0x333333 });
      const baseMesh = new THREE.Mesh(baseGeometry, baseMaterial);
      baseMesh.position.set(0, -textHeight / 2 - 5, 0); // Positionner la base sous le texte
      scene.add(baseMesh);

      // Ajouter un emoji à gauche si sélectionné
      if (emojiLeft) {
        const emojiGeometry = new TextGeometry(emojiLeft, {
          font: font,
          size: size,
          depth: depth,
        });
        const emojiMeshLeft = new THREE.Mesh(emojiGeometry, material);
        emojiMeshLeft.position.set(-textWidth / 2 - size, 10, 0); // Placer l'emoji à gauche du texte
        scene.add(emojiMeshLeft);
      }

      // Ajouter un emoji à droite si sélectionné
      if (emojiRight) {
        const emojiGeometry = new TextGeometry(emojiRight, {
          font: font,
          size: size,
          depth: depth,
        });
        const emojiMeshRight = new THREE.Mesh(emojiGeometry, material);
        emojiMeshRight.position.set(textWidth / 2 + size / 2, 10, 0); // Placer l'emoji à droite du texte
        scene.add(emojiMeshRight);
      }

      camera.position.z = 200; // Ajuster la distance de la caméra pour tout voir
      controls.update();

      // Animation
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update(); // Mise à jour des contrôles de la caméra
        renderer.render(scene, camera);
      };
      animate();

      // Cleanup quand le composant est démonté
      return () => {
        if (currentMount) {
          currentMount.removeChild(renderer.domElement);
        }
      };
    }
  }, [font, text, size, depth, emojiLeft, emojiRight, isOpen]);

  // Fonction pour exporter la scène en STL
  const downloadSTL = () => {
    if (scene) {
      const exporter = new STLExporter();
      const stlString = exporter.parse(scene);
      const blob = new Blob([stlString], { type: 'application/octet-stream' });
      saveAs(blob, 'text3d_with_support.stl');
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="text3d-modal" overlayClassName="modal-overlay" style={{ content: { width: '80%', margin: 'auto' } }}>
      <div className="modal-header">
        <h2>Générateur de Texte 3D</h2>
        <button className="close-button" onClick={onClose}>X</button>
      </div>
      <div className="modal-content">
        <div className="left-controls">
          <div className="controls">
            <label>
              Texte :
              <span onClick={() => setPickerOpen(!pickerOpen)} style={{ cursor: 'pointer', marginLeft: '10px' }}>😀</span>
            </label>
            <input
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <label>Taille :</label>
            <input type="number" value={size} onChange={(e) => setSize(Number(e.target.value))} />
            <label>Profondeur :</label>
            <input type="number" value={depth} onChange={(e) => setDepth(Number(e.target.value))} />
            {pickerOpen && (
              <>
                <label>Emoji gauche :</label>
                <Picker onEmojiSelect={(emoji) => setEmojiLeft(emoji.native)} />
                <label>Emoji droite :</label>
                <Picker onEmojiSelect={(emoji) => setEmojiRight(emoji.native)} />
              </>
            )}
          </div>
          <button onClick={downloadSTL}>Télécharger en STL</button>
        </div>
        <div ref={mountRef} className="threejs-container right-render"></div>
      </div>
    </Modal>
  );
};

Text3DModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Text3DModal;
