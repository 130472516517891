// src/components/NotFoundPage.jsx



const NotFoundPage = () => {
  return (
    <div>
      <h1>404 - Page non trouvée</h1>
      <p>La page que vous recherchez n'existe pas.</p>
    </div>
  );
};

export default NotFoundPage;
