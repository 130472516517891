import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../AdminScss/productManager.scss';

function ProductManager() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    image_url: 'no-pict.jpg',
    additional_images: [],
    add_details: '',
    category_id: '',
    subcategory_id: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  // Fonction pour obtenir le token d'authentification
  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  // Fonction pour récupérer les catégories
  const fetchCategories = useCallback(async () => {
    try {
      const response = await fetch('https://www.npm06.com/api/categories', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Erreur lors de la récupération des catégories');
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des catégories:', error);
    }
  }, [token]);

  // Fonction pour récupérer les produits
  const fetchProducts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch('https://www.npm06.com/api/products/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Erreur lors de la récupération des produits');
      const productsData = await response.json();

      const updatedProducts = productsData.map(product => {
        const category = categories.find(cat => cat.id === product.category_id);
        const subcategory = category ? category.subcategories.find(sub => sub.id === product.subcategory_id) : null;
        return {
          ...product,
          category_name: category ? category.name : 'Non défini',
          subcategory_name: subcategory ? subcategory.name : 'Non défini',
        };
      });

      setProducts(updatedProducts);
    } catch (error) {
      console.error('Erreur lors de la récupération des produits:', error);
    } finally {
      setLoading(false);
    }
  }, [categories, token]);

  useEffect(() => {
    const sessionToken = getToken();
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;

    if (!parsedUser || parsedUser.role !== 'admin' || !sessionToken) {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    } else {
      setToken(sessionToken);
    }
  }, [navigate]);

  useEffect(() => {
    if (token) {
      fetchCategories();
    }
  }, [token, fetchCategories]);

  useEffect(() => {
    if (categories.length > 0) {
      fetchProducts();
    }
  }, [categories, fetchProducts]);

  // Utiliser handleInputChange dans les champs du formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'category_id') {
      const selectedCategory = categories.find(cat => cat.id === parseInt(value));
      setSubcategories(selectedCategory ? selectedCategory.subcategories : []);
    }
  };

  // Utiliser handleFileChange pour gérer les fichiers d'image
  const handleFileChange = (e, index = null) => {
    const file = e.target.files[0];
    if (file) {
      if (index === null) {
        setFormData((prevState) => ({ ...prevState, image_url: file }));
        setPreviewImage(URL.createObjectURL(file));
      } else {
        const updatedImages = [...formData.additional_images];
        updatedImages[index] = { type: 'file', value: file, preview: URL.createObjectURL(file) };
        setFormData((prevState) => ({ ...prevState, additional_images: updatedImages }));
      }
    }
  };

  // Utiliser handleCancelImage pour annuler l'image
  const handleCancelImage = () => {
    setFormData((prevState) => ({
      ...prevState,
      image_url: 'no-pict.jpg',
    }));
    setPreviewImage(null);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name || '');
    formDataToSend.append('description', formData.description || '');
    formDataToSend.append('price', formData.price || '');
    formDataToSend.append('add_details', formData.add_details || '');
    formDataToSend.append('category_id', formData.category_id || '');
    formDataToSend.append('subcategory_id', formData.subcategory_id || '');

    if (formData.image_url && formData.image_url instanceof File) {
      formDataToSend.append('image', formData.image_url);
    } else if (selectedProduct && selectedProduct.image_url) {
      formDataToSend.append('image_url', selectedProduct.image_url);
    }

    formData.additional_images.forEach((image, index) => {
      if (image.type === 'file' && image.value instanceof File) {
        formDataToSend.append(`additional_images_${index}`, image.value);
      }
    });

    try {
      const url = selectedProduct && selectedProduct.id
        ? `https://www.npm06.com/api/products/${selectedProduct.id}`
        : `https://www.npm06.com/api/products/`;

      const method = selectedProduct ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSend,
      });

      if (response.ok) {
        fetchProducts();
        closeModal();
      } else {
        const errorMessage = await response.json();
        console.error('Erreur lors de l\'enregistrement du produit:', errorMessage);
        alert('Une erreur est survenue lors de l\'enregistrement. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement du produit:', error);
      alert('Une erreur est survenue. Veuillez vérifier votre connexion et réessayer.');
    }
  };
// Fonction pour supprimer un produit
const handleDeleteProduct = async (id) => {
  const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer ce produit ?');
  if (!confirmation) return;

  try {
    const url = `https://www.npm06.com/api/products/${id}`;

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      fetchProducts(); // Rafraîchir la liste des produits après suppression
    } else {
      const errorMessage = await response.text();
      console.error('Erreur lors de la suppression du produit:', errorMessage);
      alert('Une erreur est survenue lors de la suppression du produit.');
    }
  } catch (error) {
    console.error('Erreur lors de la suppression du produit:', error);
    alert('Une erreur est survenue. Veuillez vérifier votre connexion et réessayer.');
  }
};

  const openModal = (product = null) => {
    setSelectedProduct(product);
    setFormData({
      name: product?.name || '',
      description: product?.description || '',
      add_details: product?.add_details || '',
      price: product?.price || '',
      image_url: product?.image_url || 'no-pict.jpg',
      additional_images: Array.isArray(product?.additional_images) ? product.additional_images : [],
      category_id: product?.category_id || '',
      subcategory_id: product?.subcategory_id || '',
    });

    setPreviewImage(
      product?.image_url && product.image_url.startsWith('https')
        ? product.image_url
        : product?.image_url
        ? `https://npm06.com/uploads/${product.image_url}`
        : 'no-pict.jpg'
    );

    if (product?.category_id) {
      const selectedCategory = categories.find(cat => cat.id === product.category_id);
      setSubcategories(selectedCategory ? selectedCategory.subcategories : []);
    }

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
    setPreviewImage(null);
  };

  return (
    <div className="product-manager-container">
      <h1>Gestion des Produits</h1>
      {errorMessage && <p>{errorMessage}</p>}
      {loading && <p>Chargement...</p>}
      <button onClick={() => openModal()}>Ajouter un Produit</button>
      <table className="table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Nom</th>
            <th>Catégorie</th>
            <th>Sous-catégorie</th>
            <th>Description</th>
            <th>Détails supplémentaires</th>
            <th>Prix</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.length > 0 ? (
            products.map((product) => (
              <tr key={product.id}>
                <td>
                  <img
                    src={
                      product.image_url.startsWith('https')
                        ? product.image_url
                        : `https://npm06.com/uploads/${product.image_url}`
                    }
                    alt={product.name}
                    style={{ width: '50px', height: '50px' }}
                  />
                </td>
                <td>{product.name}</td>
                <td>{product.category_name}</td>
                <td>{product.subcategory_name}</td>
                <td>{product.description}</td>
                <td>{product.add_details}</td>
                <td>{product.price}</td>
                <td>
                  <button onClick={() => openModal(product)}>Modifier</button>
                  <button onClick={() => handleDeleteProduct(product.id)}>Supprimer</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">Aucun produit disponible</td>
            </tr>
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <form onSubmit={handleSave}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Nom du produit"
                required
              />
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Description"
                required
              />
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                placeholder="Prix"
                required
              />
              <div>
                <label htmlFor="category">Catégorie</label>
                <select
                  name="category_id"
                  value={formData.category_id}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Sélectionner une catégorie</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="subcategory">Sous-catégorie</label>
                <select
                  name="subcategory_id"
                  value={formData.subcategory_id}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Sélectionner une sous-catégorie</option>
                  {subcategories.map((subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="image_url">Image principale</label>
                <input
                  type="file"
                  name="image_url"
                  onChange={(e) => handleFileChange(e)}
                />
                {previewImage && (
                  <div className="image-preview">
                    <img
                      src={previewImage}
                      alt="Prévisualisation"
                      style={{ width: '100px', height: '100px' }}
                    />
                    <button type="button" onClick={handleCancelImage}>
                      Annuler l'image
                    </button>
                  </div>
                )}
              </div>
              <button type="submit">{selectedProduct ? 'Mettre à jour' : 'Ajouter'}</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductManager;
