import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import SidebarCat from './SidebarCat';
import '../scss/Shopping.scss';

Modal.setAppElement('#root');

const Shopping = () => {
  const { products, fetchProducts, addToCart } = useContext(AppContext);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sortOrder, setSortOrder] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  // Fonction pour afficher tous les produits
  const handleShowAllProducts = () => {
    setFilteredProducts(products);
    setIsModalOpen(false); // Fermer le modal après l'affichage de tous les produits
  };

  const handleCategorySelect = (category) => {
    const produitsFiltres = products.filter(
      (product) => product.category_id === category.id
    );
    setFilteredProducts(produitsFiltres.length > 0 ? produitsFiltres : []);
    if (!produitsFiltres.length) setIsModalOpen(true);
  };

  const handleSortChange = (e) => {
    const sortValue = e.target.value;
    setSortOrder(sortValue);
    const sortedProducts = [...filteredProducts].sort((a, b) =>
      sortValue === 'asc' ? a.price - b.price : b.price - a.price
    );
    setFilteredProducts(sortedProducts);
  };

  const handleAddToCart = (product) => {
    addToCart(product);
  };

  // Fonction pour basculer l'affichage de la sidebar
  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  return (
    <div className={`shopping-container ${isSidebarVisible ? 'sidebar-open' : ''}`}>
      <div className="hamburger-icon" onClick={toggleSidebar}>
        ☰ <span className="hamburger-text">Catégories</span>
      </div>

      {isSidebarVisible && (
        <SidebarCat
          allowedCategories={[1, 2, 3, 4, 5, 6]}
          onSelectCategory={handleCategorySelect}
          isOpen={isSidebarVisible}
          onClose={() => setIsSidebarVisible(false)}
        />
      )}

      <div className="products-content">
        <div className="header-section">
          <h2>Nos Produits</h2>
          <div className="filter-section">
            <label htmlFor="sortOrder">Trier par prix : </label>
            <select id="sortOrder" value={sortOrder} onChange={handleSortChange}>
              <option value="">Sélectionnez</option>
              <option value="asc">Prix croissant</option>
              <option value="desc">Prix décroissant</option>
            </select>
          </div>
        </div>

        <div className="products-grid">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <div key={product.id} className="products-card">
                <h3>{product.name}</h3>
                <img
                  src={`https://npm06.com/uploads/${product.image_url}`}
                  alt={product.name}
                  style={{ width: '150px', height: '150px' }}
                />
                <p>
                  {product.description.length > 50
                    ? `${product.description.substring(0, 50)}...`
                    : product.description}
                </p>
                <p className="price">{product.price} €</p>
                <Link to={`/productDetail/${product.id}`} className="detail-button">
                  Voir Détail
                </Link>
                <button className="add-to-cart-button" onClick={() => handleAddToCart(product)}>
                  Ajouter au Panier
                </button>
              </div>
            ))
          ) : (
            <p>Aucun produit disponible pour le moment.</p>
          )}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Avertissement"
        className="warning-modal"
        overlayClassName="modal-overlay"
      >
        <h2>Aucun produit disponible</h2>
        <p>Il n'y a pas de produits dans cette catégorie ou sous-catégorie.</p>
        <button onClick={handleShowAllProducts}>Afficher tous les produits</button>
      </Modal>
    </div>
  );
};

export default Shopping;
