import '../scss/Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <h3>NPM RUN DEV</h3>
        
        <div className="footer-menu">
          <ul>
            <li><a href="#about">Qui sommes-nous</a></li>
            <li><a href="#news">Actualités</a></li>
            <li><a href="#sitemap">Plan du site</a></li>
          </ul>
        </div>
        
        <div className="footer-columns">
          <div className="footer-column">
            <h4>Services et Garanties</h4>
            <ul>
              <li><a href="#">Service 1</a></li>
              <li><a href="#">Service 2</a></li>
              <li><a href="#">Service 3</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Livraisons et Paiement</h4>
            <ul>
              <li><a href="#">Livraison 1</a></li>
              <li><a href="#">Livraison 2</a></li>
              <li><a href="#">Paiement 1</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Contactez-nous</h4>
            <ul>
              <li><a href="/contact">Mail</a></li>
              <li><a href="/contact">Adresse </a></li>
              <li><a href="/contact">Téléphone</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Informations légales</h4>
            <ul>
              <li><a href="MentionsLegales">Mentions légales</a></li>
              <li><a href="/conditions-generales-vente">Conditions Générales de Vente</a></li>
              <li><a href="#">Politique de confidentialité</a></li>
            </ul>
          </div>
        </div>
        
        <div className="social-icons">
          <ul>
            <li><a href="#" target="_blank" rel="noopener noreferrer"><i className="icon-instagram"></i> Instagram</a></li>
            <li><a href="https://www.facebook.com/groups/nicemoulinssolidarites06" target="_blank" rel="noopener noreferrer"><i className="icon-facebook"></i> Facebook</a></li>
            <li><a href="#" target="_blank" rel="noopener noreferrer"><i className="icon-twitter"></i> Twitter</a></li>
            <li><a href="https://www.youtube.com/@NonoChannel06" target="_blank" rel="noopener noreferrer"><i className="icon-youtube"></i> YouTube</a></li>
          </ul>
        </div>

        <div className="copyright">
          <p>&copy; 2024 NPM RUN DEV. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
