import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../AdminScss/YouTubeManager.scss';

function YouTubeManager() {
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    youtube_url: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Fonction pour récupérer le token depuis localStorage ou sessionStorage
  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const token = getToken();

    if (!parsedUser || parsedUser.role !== 'admin' || !token) {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    } else {
      fetchVideos(token);
    }
  }, [navigate]);

  // Récupérer toutes les vidéos
  const fetchVideos = async (token) => {
    try {
      const response = await fetch('https://npm06.com/api/youtubevideos', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des vidéos');
      }
      const data = await response.json();
      setVideos(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des vidéos:', error);
    }
  };

  // Gérer les changements dans le formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Sauvegarder une nouvelle vidéo ou mettre à jour une vidéo existante
  const handleSave = async (e) => {
    e.preventDefault();

    try {
      const token = getToken();
      const url = selectedVideo
        ? `https://npm06.com/api/youtubevideos/${selectedVideo.id}`
        : `https://localhost:3000/api/youtubevideos/`;
      const method = selectedVideo ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        fetchVideos(token);
        closeModal();
      } else {
        const errorMessage = await response.text();
        console.error('Erreur lors de l\'enregistrement de la vidéo:', errorMessage);
        alert('Une erreur est survenue lors de l\'enregistrement. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de la vidéo:', error);
      alert('Une erreur est survenue. Veuillez vérifier votre connexion et réessayer.');
    }
  };

  // Supprimer une vidéo
  const handleDeleteVideo = async (id) => {
    const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer cette vidéo ?');
    if (!confirmation) return;

    try {
      const token = getToken();
      const response = await fetch(`https://npm06.com/api/youtubevideos/${id}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        fetchVideos(token);
      } else {
        console.error('Erreur lors de la suppression de la vidéo:', response.statusText);
        alert('Une erreur est survenue lors de la suppression.');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de la vidéo:', error);
      alert('Une erreur est survenue. Veuillez vérifier votre connexion et réessayer.');
    }
  };

  // Ouvrir le modal pour ajouter/modifier une vidéo
  const openModal = (video = null) => {
    setSelectedVideo(video);
    setFormData({
      title: video?.title || '',
      description: video?.description || '',
      youtube_url: video?.youtube_url || '',
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideo(null);
  };

  return (
    <div className="youtube-manager-container">
      <h1>Gestion des Vidéos YouTube</h1>
      {errorMessage && <p>{errorMessage}</p>}
      <button onClick={() => openModal()}>Ajouter une vidéo</button>
      <table className="table">
        <thead>
          <tr>
            <th>Miniature</th>
            <th>Titre</th>
            <th>Description</th>
            <th>Lien YouTube</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {videos.length > 0 ? (
            videos.map((video) => (
              <tr key={video.id}>
                <td>
                  <img
                    src={`https://img.youtube.com/vi/${video.youtubeId}/hqdefault.jpg`}
                    alt={video.title}
                    style={{ width: '50px', height: '50px' }}
                  />
                </td>
                <td>{video.title}</td>
                <td>{video.description}</td>
                <td>
                  <a href={`https://www.youtube.com/watch?v=${video.youtubeId}`} target="_blank" rel="noopener noreferrer">
                    Voir la vidéo
                  </a>
                </td>
                <td>
                  <button onClick={() => openModal(video)}>Modifier</button>
                  <button onClick={() => handleDeleteVideo(video.id)}>Supprimer</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">Aucune vidéo disponible</td>
            </tr>
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <h2>{selectedVideo ? 'Modifier la vidéo' : 'Ajouter une vidéo'}</h2>
            <form onSubmit={handleSave}>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Titre"
                required
              />
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Description"
                required
              />
              <input
                type="text"
                name="youtube_url"
                value={formData.youtube_url}
                onChange={handleInputChange}
                placeholder="Lien YouTube"
                required
              />
              <button type="submit">{selectedVideo ? 'Mettre à jour' : 'Ajouter'}</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default YouTubeManager;
