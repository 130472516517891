// src/context/Store_redux/reducers/someReducer.js

// État initial pour ce reducer
const initialState = {
    data: [],
    isLoading: false,
    error: null,
  };
  
  // Reducer pour gérer les actions liées à "someReducer"
  export default function someReducer(state = initialState, action) {
    switch (action.type) {
      case 'FETCH_DATA_REQUEST':
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case 'FETCH_DATA_SUCCESS':
        return {
          ...state,
          isLoading: false,
          data: action.payload,
        };
      case 'FETCH_DATA_FAILURE':
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  }
  