import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import Modal from 'react-modal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../scss/Slider.scss';

Modal.setAppElement('#root');

const SliderComponent = () => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [images, setImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`https://npm06.com/api/products`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) throw new Error('Erreur réseau');
                return response.json();
            })
            .then(data => {
                const processedImages = data
                    .filter(product => product.image_url)
                    .map(product => ({
                        ...product,
                        image_url: product.image_url.startsWith('https')
                            ? product.image_url
                            : `https://npm06.com/uploads/${product.image_url}`,
                    }));
                const shuffledImages = processedImages.sort(() => Math.random() - 0.5);
                setImages(shuffledImages);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des images:', error);
                setError('Erreur lors du chargement des images.');
            });
    }, []);

    const mainSettings = {
        asNavFor: nav2,
        ref: (slider) => setNav1(slider),
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true,
        centerMode: true,
        centerPadding: '0',
        focusOnSelect: true,
    };

    const thumbSettings = {
        asNavFor: nav1,
        ref: (slider) => setNav2(slider),
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        centerMode: true,
        centerPadding: '0px',
        infinite: true,
    };

    const openModalWithVideo = (videoUrl) => {
        const videoId = videoUrl.split('v=')[1];
        setCurrentVideoUrl(videoId);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentVideoUrl('');
    };

    const handleImageClick = (productId) => {
        navigate(`/productdetail/${productId}`);
    };

    return (
        <div className="slider-container">
            {error && <div className="error-message">{error}</div>}

            <Slider {...mainSettings} className="main-slider">
                {images.map((image, index) => (
                    <div key={index}>
                        {image.type === 'youtube' ? (
                            <div className="youtube-thumbnail" onClick={() => openModalWithVideo(image.video_url)}>
                                <img
                                    src={`https://img.youtube.com/vi/${image.video_id}/hqdefault.jpg`}
                                    alt={image.alt_text || `Video ${index + 1}`}
                                    className="slider-image"
                                />
                                <div className="play-button-overlay">▶️</div>
                            </div>
                        ) : (
                            <img
                                src={image.image_url}
                                alt={image.alt_text || `Slide ${index + 1}`}
                                className="slider-image"
                                onClick={() => handleImageClick(image.id)}
                                style={{ cursor: 'pointer' }}
                            />
                        )}
                    </div>
                ))}
            </Slider>

            <Slider {...thumbSettings} className="slider-thumbs">
                {images.map((image, index) => (
                    <div key={index}>
                        <img
                            src={image.image_url}
                            alt={image.alt_text || `Thumbnail ${index + 1}`}
                            className="slider-thumb-image"
                        />
                    </div>
                ))}
            </Slider>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Vidéo YouTube"
                className="video-modal"
                overlayClassName="video-modal-overlay"
            >
                <div className="video-container">
                    <iframe
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/${currentVideoUrl}`}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="YouTube video"
                    ></iframe>
                    <button onClick={closeModal} className="close-modal-button">Fermer</button>
                </div>
            </Modal>
        </div>
    );
};

export default SliderComponent;
