import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../AdminScss/annonceManager.scss';

const AnnoncesManager = () => {
  const [annonces, setAnnonces] = useState([]);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Fonction pour récupérer le token et le rôle
  const getTokenAndRole = () => {
    const token = localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
    const user = localStorage.getItem('user') || sessionStorage.getItem('user');
    const role = user ? JSON.parse(user).role : null; // Extraire le rôle de l'objet utilisateur
    return { token, role };
  };

  // Fonction pour récupérer les annonces
  const fetchAnnonces = async () => {
    setLoading(true);
    const { token, role } = getTokenAndRole();

    if (!token || role !== 'admin') {
      setErrorMessage("Accès refusé. Vous n'avez pas les droits d'administrateur.");
      navigate('/login');
      return;
    }

    try {
      const response = await axios.get('https://www.npm06.com/api/annonces/?showAll=true', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAnnonces(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des annonces:', error);
      if (error.response && error.response.status === 401) {
        setErrorMessage("Accès refusé, veuillez vous reconnecter.");
        navigate('/login');
      } else {
        setErrorMessage('Erreur de récupération des annonces.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour basculer l'approbation d'une annonce
// Fonction pour basculer l'approbation d'une annonce avec des logs
const toggleApproval = async (annonceId, isApproved) => {
  const { token } = getTokenAndRole();
  console.log(`Tentative d'approbation de l'annonce avec ID: ${annonceId}, état actuel: ${isApproved}`);

  try {
    // Appel de la route pour approuver ou désapprouver l'annonce
    console.log(`Envoi de la requête PUT à /api/annonces/${annonceId}/approve`);
    const response = await axios.put(
      `https://www.npm06.com/api/annonces/${annonceId}/approve`,
      { isApproved: !isApproved }, // Basculer l'état
      {
        headers: { Authorization: `Bearer ${token}` }, // Ajout de l'en-tête
      }
    );

    // Afficher le message de réponse
    console.log(`Réponse reçue du serveur :`, response.data);
    setMessage(response.data.message);

    // Recharger la liste des annonces après mise à jour
    fetchAnnonces();
  } catch (error) {
    // Log des erreurs en cas d'échec
    console.error('Erreur lors de la mise à jour de l\'annonce:', error);
    setMessage("Une erreur est survenue lors de la mise à jour.");
  }
};


  useEffect(() => {
    fetchAnnonces();
  }, []);

  return (
    <div className="annonce-manager-container">
      <h1>Gestion des Annonces (Admin)</h1>
      {errorMessage && <p>{errorMessage}</p>}
      {loading && <p>Chargement...</p>}
      {message && <p>{message}</p>}

      <table className="table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Titre</th>
            <th>Description</th>
            <th>Prix</th>
            <th>Utilisateur</th>
            <th>Sous-Catégorie</th>
            <th>Date de création</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {annonces.length > 0 ? (
            annonces.map((annonce) => (
              <tr key={annonce.id}>
                <td>
                  <img
                    src={annonce.image_url.startsWith('https') ? annonce.image_url : `https://npm06.com/uploads/${annonce.image_url}`}
                    alt={annonce.title}
                    style={{ width: '50px', height: '50px' }}
                  />
                </td>
                <td>{annonce.title}</td>
                <td>{annonce.description}</td>
                <td>{annonce.price} €</td>
                <td>
                  {/* Nom de l'utilisateur cliquable */}
                  {annonce.user ? (
                    <button onClick={() => navigate(`/userProfile/${annonce.user.id}`)}>
                      {annonce.user.username}
                    </button>
                  ) : (
                    'Utilisateur inconnu'
                  )}
                </td>
                <td>{annonce.subcategory?.name || 'Aucune sous-catégorie'}</td>
                <td>{annonce.created_at ? new Date(annonce.created_at).toLocaleDateString() : 'Date non disponible'}</td>
                <td>
                  <button
                    onClick={() => toggleApproval(annonce.id, annonce.is_approved)}
                  >
                    {annonce.is_approved ? 'Désapprouver' : 'Approuver'}
                  </button>
                  <button onClick={() => navigate(`/annonceDetail/${annonce.id}`)}>Voir Détail</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">Aucune annonce disponible</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AnnoncesManager;
