const initialState = {
  user: null,
  token: null, // Ajout du token pour les sessions
  error: null, // Ajout pour gérer les erreurs d'authentification
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        user: action.payload.user, // Met à jour l'état avec l'utilisateur connecté
        token: action.payload.token, // Enregistre le token
        error: null, // Réinitialise les erreurs
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        user: null,
        token: null,
        error: action.payload.error, // Garde l'erreur d'authentification
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
        token: null, // Réinitialise le token lors de la déconnexion
        error: null, // Réinitialise les erreurs
      };
    default:
      return state;
  }
}

export default authReducer;
