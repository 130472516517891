import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Pour la redirection
import '../scss/MyOrders.scss'; // Réutilisation des styles de ProductManager

const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate(); // Initialiser useNavigate pour la navigation

  // Fonction pour récupérer le token
  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  // Récupération des commandes lors du chargement du composant
  useEffect(() => {
    const token = getToken();
    if (!token) {
      setErrorMessage("Veuillez vous connecter pour accéder à vos commandes.");
      navigate('/login');
    } else {
      fetchOrders(token);
    }
  }, [navigate]);

  // Fonction pour récupérer les commandes
  const fetchOrders = async (token) => {
    try {
      const response = await fetch('https://npm06.com/api/orders', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Erreur lors de la récupération des commandes');
      const data = await response.json();
      setOrders(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des commandes:', error);
      setErrorMessage('Impossible de récupérer les commandes. Veuillez réessayer plus tard.');
    } finally {
      setIsLoading(false);
    }
  };

  // Affichage des détails d'une commande dans un modal
  const viewOrderDetails = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  // Fermeture du modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  // Redirection vers la page produit
  const handleProductClick = (productId) => {
    navigate(`/productdetail/${productId}`); // Navigue vers la page de détails du produit
  };

  return (
    <div className="my-orders-container">
      <h1>Mes Commandes</h1>

      {isLoading ? (
        <p>Chargement des commandes...</p>
      ) : (
        <>
          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <table className="table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Date</th>
                <th>Prix Total</th>
                <th>Frais Livraison</th>
                <th>Statut</th>
                <th>ID</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.length > 0 ? (
                orders.map((order) => {
                  const firstItem = order.items && order.items.length > 0 ? order.items[0] : null;
                  const imageUrl = firstItem ? `https://npm06.com/uploads/${firstItem.image_url}` : 'https://npm06.com/uploads/no-pict.jpg';

                  return (
                    <tr key={order.order_id}>
                      <td className="image-cell">
                        <img 
                          src={imageUrl} 
                          alt={`Image de la commande ${order.order_id}`} 
                          className="order-thumbnail"
                        />
                      </td>
                      <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                      <td>{order.total_price} €</td>
                      <td>{order.delivery_price ? order.delivery_price : '4.00'} €</td>
                      <td>{order.status}</td>
                      <td>{order.order_id}</td>
                      <td>
                        <button onClick={() => viewOrderDetails(order)}>Détails</button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7">Aucune commande disponible</td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}

      {isModalOpen && selectedOrder && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <h2>Détails de la commande #{selectedOrder.order_id}</h2>
            <p>Total : {selectedOrder.total_price} €</p>
            <p>Frais de livraison : {selectedOrder.delivery_price ? selectedOrder.delivery_price : '4.00'} €</p>
            <p>Statut : {selectedOrder.status}</p>
            <p>Payé : {selectedOrder.is_paid ? 'Oui' : 'Non'}</p>

            {/* Tableau des articles dans le modal */}
            <table className="table items-table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Article</th>
                  <th>Prix Unitaire</th>
                  <th>Quantité</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {selectedOrder.items && selectedOrder.items.length > 0 ? (
                  selectedOrder.items.map((item, index) => {
                    const itemImageUrl = `https://npm06.com/uploads/${item.image_url}`;

                    return (
                      <tr key={index}>
                        <td className="image-cell">
                          <img 
                            src={itemImageUrl} 
                            alt={item.title} 
                            className="item-thumbnail" 
                            onClick={() => handleProductClick(item.id)} // Navigue vers la fiche produit
                            style={{ cursor: 'pointer' }} // Ajoute le curseur pointer pour indiquer que c'est cliquable
                          />
                        </td>
                        <td>{item.title}</td>
                        <td>{item.price} €</td>
                        <td>{item.quantity}</td>
                        <td>{(item.price * item.quantity).toFixed(2)} €</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">Aucun article dans cette commande</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyOrders;
