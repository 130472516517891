import PropTypes from 'prop-types';
import '../scss/StepsSection.scss';

const StepsSection = ({ steps, serviceType }) => {
  return (
    <div className="steps-section">
      <h2>Comment fonctionne notre service de {serviceType} ?</h2>
      <ul>
        {steps.map((step, index) => (
          <li key={index}>{index + 1}. {step}</li>
        ))}
      </ul>
    </div>
  );
};

StepsSection.propTypes = {
  steps: PropTypes.array.isRequired,
  serviceType: PropTypes.string.isRequired,
};

export default StepsSection;
