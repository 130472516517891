import '../AdminScss/DashboardHeader.scss';

const DashboardHeader = () => {
  return (
    <header className="dashboard-header">
      <h1>Mon Tableau de Bord</h1>
    </header>
  );
};

export default DashboardHeader;
