import { useContext } from 'react';
import '../scss/CartPreview.scss'; // SCSS lié au composant
import { AppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';

const CartPreview = () => {
  const { cart } = useContext(AppContext);
  const navigate = useNavigate();

  // Calcul du total
  const calculatedTotal = cart.reduce((acc, item) => {
    const price = parseFloat(item.price);
    const quantity = parseInt(item.quantity, 10) || 1; // Utilisation d'une valeur par défaut de 1
    return acc + (isNaN(price) ? 0 : price * quantity);
  }, 0);

  // Redirection vers la page panier
  const handleViewCart = () => {
    navigate('/cart');
  };

  return (
    <div className="cart-preview">
      <h2>Mon Panier</h2>
      {cart.length === 0 ? (
        <p>Votre panier est vide</p>
      ) : (
        <div className="cart-items">
          {cart.map((item, index) => {
            const price = parseFloat(item.price);
            const quantity = parseInt(item.quantity, 10) || 1; // Utilisation d'une valeur par défaut de 1
            const image_url = item.image_url
              ? `https://npm06.com/uploads/${item.image_url}`
              : 'https://via.placeholder.com/150x150';

            return (
              <div key={`${item.product_id}-${index}`} className="cart-item">
                <img
                  src={image_url}
                  alt={item.name}
                  className="cart-item-image"
                  onError={(e) => {
                    e.target.src = 'https://via.placeholder.com/150x150'; // Image de secours
                  }}
                />
                <div className="cart-item-details">
                  <p>{item.name}</p>
                  <p>{quantity} x {isNaN(price) ? '0.00' : price.toFixed(2)} €</p>
                </div>
              </div>
            );
          })}

          <div className="cart-total">
            <p>Total: {calculatedTotal.toFixed(2)} €</p>
          </div>

          {/* Bouton pour aller au panier */}
          <button className="view-cart-btn" onClick={handleViewCart}>
            Voir mon panier
          </button>
        </div>
      )}
    </div>
  );
};

export default CartPreview;
