import { Link } from 'react-router-dom'; // Supprime useNavigate car il n'est pas nécessaire
import PropTypes from 'prop-types';
import '../scss/Navigateur.scss';

const Navigateur = ({ isAdmin }) => {
  return (
    <nav className="nav-bar">
      <ul className="nav-links">
        <li><Link to="/ServiceOverview">Services</Link></li>
        <li><Link to="/Shopping">Boutique</Link></li>
        <li><Link to="/Annonce">Annonce</Link></li>
        <li><Link to="/sublimation-service">Sublimation</Link></li> {/* Lien ajouté pour la page Sublimation */}
        <li><Link to="/impression3dservice">Impression 3D</Link></li>
        <li><Link to="/YouTubeVideos">Vidéos YouTube</Link></li>
        {isAdmin && (
          <>
            <li><Link to="/admin/dashboardlayout">Dashboard</Link></li>
            <li><Link to="/CleanUploads">Nettoyage Upload</Link></li>
          </>
        )}
       
      </ul>
    </nav>
  );
};

Navigateur.propTypes = {
  isAdmin: PropTypes.bool.isRequired, // Pour savoir si l'utilisateur est admin
};

export default Navigateur;
