import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../AdminScss/ProductManager.scss';
import axios from 'axios';

function EventManager() {
  const [events, setEvents] = useState([]); // Liste des événements
  const [selectedEvent, setSelectedEvent] = useState(null); // Événement sélectionné
  const [isModalOpen, setIsModalOpen] = useState(false); // État d'ouverture de la modale
  const [token, setToken] = useState(null); // Token de session
  const [errorMessage, setErrorMessage] = useState(''); // Message d'erreur
  const [loading, setLoading] = useState(false); // Indicateur de chargement
  const [isFileModalOpen, setIsFileModalOpen] = useState(false); // Gestion de la modale pour visualiser le fichier
  const [selectedFile, setSelectedFile] = useState(null); // Fichier sélectionné pour la visualisation
  const [replyMessage, setReplyMessage] = useState(''); // Message de réponse
  const [uploadedFile, setUploadedFile] = useState(null); // Fichier principal à envoyer
  const [additionalFiles, setAdditionalFiles] = useState([]); // Fichiers supplémentaires à envoyer
  const [prenom, setPrenom] = useState(''); // Champ prénom
  const [nom, setNom] = useState(''); // Champ nom
  const [email, setEmail] = useState(''); // Champ email
  const [description, setDescription] = useState(''); // Champ description
  const [typeService, setTypeService] = useState(''); // Champ type de service
  const [dateEvenement, setDateEvenement] = useState(''); // Champ date d'événement

  const navigate = useNavigate(); // Pour rediriger l'utilisateur

  // Récupération du token de session (localStorage ou sessionStorage)
  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  // Fonction pour récupérer la liste des événements depuis l'API
  const fetchEvents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://www.npm06.com/api/demande_devis/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEvents(response.data); // Mettre à jour les événements
    } catch (error) {
      console.error("Erreur lors de la récupération des événements:", error);
      alert("Erreur de récupération des événements. Veuillez réessayer.");
    } finally {
      setLoading(false); // Arrêter l'indicateur de chargement
    }
  }, [token]);

  // Vérification du token et des autorisations d'admin
  useEffect(() => {
    const sessionToken = getToken();
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;

    if (!parsedUser || parsedUser.role !== 'admin' || !sessionToken) {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login'); // Redirige vers la page de login si non admin
    } else {
      setToken(sessionToken); // Stocker le token
    }
  }, [navigate]);

  // Charger les événements une fois le token disponible
  useEffect(() => {
    if (token) {
      fetchEvents(); // Charger les événements
    }
  }, [token, fetchEvents]);

  // Fonction pour ouvrir la modale avec les champs pré-remplis si un événement est sélectionné
  const openModal = (event = null) => {
    setSelectedEvent(event);
    setIsModalOpen(true);

    if (event) {
      // Pré-remplir les champs avec les données de l'événement sélectionné
      setNom(event.nom || '');
      setPrenom(event.prenom || '');
      setEmail(event.email || '');
      setDescription(event.description || '');
      setTypeService(event.type_service || '');
      setDateEvenement(event.date_evenement || '');
      setReplyMessage(''); // Initialiser le champ de message de réponse à vide
      setUploadedFile(null); // Réinitialiser le fichier sélectionné
      setAdditionalFiles([]); // Réinitialiser les fichiers supplémentaires
    }
  };

  // Fonction pour fermer la modale
  const closeModal = () => {
    setIsModalOpen(false); // Fermer la modale
    setSelectedEvent(null); // Réinitialiser l'événement sélectionné
    setReplyMessage(''); // Réinitialiser le message de réponse
    setUploadedFile(null); // Réinitialiser le fichier sélectionné
    setAdditionalFiles([]); // Réinitialiser les fichiers supplémentaires
  };

  // Fonction pour ouvrir la modale de visualisation de fichier
  const openFileModal = (file) => {
    setSelectedFile(file); // Définir le fichier sélectionné
    setIsFileModalOpen(true); // Ouvrir la modale de fichier
  };

  // Fonction pour fermer la modale de fichier
  const closeFileModal = () => {
    setIsFileModalOpen(false); // Fermer la modale de fichier
    setSelectedFile(null); // Réinitialiser le fichier sélectionné
  };

  // Fonction pour gérer la suppression d'un événement
  const handleDeleteEvent = async (id) => {
    const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer cet événement ?");
    if (!confirmation) return;

    try {
      await axios.delete(`https://www.npm06.com/api/demande_devis/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchEvents(); // Recharger les événements après suppression
    } catch (error) {
      console.error("Erreur lors de la suppression de l'événement:", error);
      alert("Erreur de suppression. Veuillez vérifier votre connexion et réessayer.");
    }
  };

  // Fonction pour capturer le fichier principal sélectionné
  const handleFileChange = (e) => {
    setUploadedFile(e.target.files[0]); // Stocker le fichier sélectionné
  };

  // Fonction pour capturer les fichiers supplémentaires sélectionnés
  const handleAdditionalFilesChange = (e) => {
    setAdditionalFiles([...e.target.files]); // Mettre à jour les fichiers supplémentaires
  };

  // Fonction pour soumettre la réponse avec des fichiers (le cas échéant)
  const handleReplySubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('nom', nom);
      formData.append('prenom', prenom);
      formData.append('description', description);
      formData.append('type_service', typeService);
      formData.append('date_evenement', dateEvenement);
      formData.append('message', replyMessage);

      // Ajout du fichier principal (image)
      if (uploadedFile) {
        formData.append('image', uploadedFile); // Correspond à la clé attendue par Multer
      }

      // Si plusieurs fichiers supplémentaires sont sélectionnés
      if (additionalFiles && additionalFiles.length > 0) {
        additionalFiles.forEach(file => {
          formData.append('additional_images', file); // Utilise 'additional_images' pour correspondre à Multer
        });
      }

      const response = await axios.post('https://www.npm06.com/api/email/admin-event-reply', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        alert("Le devis a été envoyé avec succès.");
        // Effacer les fichiers uploadés après envoi réussi
        setUploadedFile(null);
        setAdditionalFiles([]); // Réinitialiser les fichiers supplémentaires
        closeModal(); // Fermer la modale après succès
      } else {
        alert("Erreur lors de l'envoi du devis. Veuillez réessayer.");
      }
    } catch (error) {
      // Log les détails de l'erreur
      if (error.response) {
        console.error("Erreur dans la réponse du serveur :", error.response.data);
        console.error("Statut :", error.response.status);
        console.error("Headers :", error.response.headers);
      } else if (error.request) {
        console.error("Erreur dans la requête :", error.request);
      } else {
        console.error("Erreur lors de l'envoi de la requête :", error.message);
      }
      console.error("Config de la requête :", error.config);
      alert("Erreur lors de l'envoi du devis. Veuillez vérifier votre connexion et réessayer.");
    }
  };

  return (
    <div className="event-manager-container">
      <h1>Gestion des Événements</h1>
      {errorMessage && <p>{errorMessage}</p>} {/* Affichage du message d'erreur */}
      {loading && <p>Chargement...</p>} {/* Affichage de l'indicateur de chargement */}
      <button onClick={() => openModal()}>Ajouter un Événement</button> {/* Bouton pour ajouter un événement */}

      {/* Tableau des événements */}
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Email</th>
            <th>Téléphone</th>
            <th>Description</th>
            <th>Type de Service</th>
            <th>Date de l'Événement</th>
            <th>Fichier Vidéo</th>
            <th>Fichier Image</th>
            <th>Utilisateur ID</th>
            <th>Créé le</th>
            <th>Mis à jour le</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {events.length > 0 ? (
            events.map((event) => (
              <tr key={event.id}>
                <td>{event.id}</td>
                <td>{event.nom}</td>
                <td>{event.prenom}</td>
                <td>{event.email}</td>
                <td>{event.telephone}</td>
                <td>{event.description}</td>
                <td>{event.type_service}</td>
                <td>{!isNaN(Date.parse(event.date_evenement)) ? new Date(event.date_evenement).toLocaleDateString() : 'Non disponible'}</td>
                <td>
                  {event.fichier_video ? (
                    <button onClick={() => openFileModal(event.fichier_video)}>Voir Vidéo</button>
                  ) : (
                    'Aucun'
                  )}
                </td>
                <td>
                  {event.fichier_image ? (
                    <img
                      src={`https://www.npm06.com/uploads/${event.fichier_image}`}
                      alt="Aperçu"
                      style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                      onClick={() => openFileModal(event.fichier_image)}
                    />
                  ) : (
                    'Aucun'
                  )}
                </td>
                <td>{event.user_id}</td>
                <td>{event.createdAt && !isNaN(Date.parse(event.createdAt)) ? new Date(event.createdAt).toLocaleString() : 'Non disponible'}</td>
                <td>{event.updatedAt && !isNaN(Date.parse(event.updatedAt)) ? new Date(event.updatedAt).toLocaleString() : 'Non disponible'}</td>
                <td>
                  <button onClick={() => openModal(event)}>Répondre</button>
                  <button onClick={() => handleDeleteEvent(event.id)}>Supprimer</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="14">Aucun événement disponible</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modale pour la réponse à l'événement */}
      {isModalOpen && selectedEvent && (
        <div className="modal">
          <div className="modal-content" style={{ width: '700px', maxWidth: '90%', padding: '20px' }}>
            <span className="close-button" onClick={closeModal}>&times;</span>
            <h2>Répondre à la demande</h2>
            <form style={{ display: 'flex', flexDirection: 'column' }}>
              <label htmlFor="email" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Email :</label>
              <input
                type="text"
                id="email"
                name="email"
                value={email}
                readOnly
                style={{ marginBottom: '20px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
              />
              <label htmlFor="nom" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Nom :</label>
              <input
                type="text"
                id="nom"
                name="nom"
                value={nom}
                readOnly
                style={{ marginBottom: '20px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
              />
              <label htmlFor="prenom" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Prénom :</label>
              <input
                type="text"
                id="prenom"
                name="prenom"
                value={prenom}
                readOnly
                style={{ marginBottom: '20px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
              />
              <label htmlFor="description" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Description :</label>
              <textarea
                id="description"
                name="description"
                value={description}
                readOnly
                rows="3"
                style={{ marginBottom: '20px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
              ></textarea>
              <label htmlFor="type_service" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Type de Service :</label>
              <input
                type="text"
                id="type_service"
                name="type_service"
                value={typeService}
                readOnly
                style={{ marginBottom: '20px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
              />
              <label htmlFor="date_evenement" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Date de l'Événement :</label>
              <input
                type="text"
                id="date_evenement"
                name="date_evenement"
                value={dateEvenement}
                readOnly
                style={{ marginBottom: '20px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
              />
              <label htmlFor="message" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Message :</label>
              <textarea
                id="message"
                name="message"
                value={replyMessage}
                onChange={(e) => setReplyMessage(e.target.value)}
                placeholder="Entrez votre message ici..."
                rows="10"
                style={{ marginBottom: '20px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                required
              ></textarea>

              <label htmlFor="file" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Ajouter un fichier :</label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleFileChange}
                style={{ marginBottom: '20px' }}
              />

              <label htmlFor="additional_files" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Ajouter des fichiers supplémentaires :</label>
              <input
                type="file"
                id="additional_files"
                name="additional_files"
                multiple
                onChange={handleAdditionalFilesChange}
                style={{ marginBottom: '20px' }}
              />

              <button type="button" onClick={handleReplySubmit} style={{ padding: '10px', backgroundColor: '#0078D4', color: '#fff', borderRadius: '5px', border: 'none', cursor: 'pointer' }}>
                Envoyer
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Modale de visualisation des fichiers */}
      {isFileModalOpen && selectedFile && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeFileModal}>&times;</span>
            {selectedFile.endsWith('.jpg') || selectedFile.endsWith('.png') || selectedFile.endsWith('.jpeg') || selectedFile.endsWith('.pdf') ? (
              selectedFile.endsWith('.pdf') ? (
                <embed src={`https://www.npm06.com/uploads/${selectedFile}`} type="application/pdf" width="100%" height="500px" />
              ) : (
                <img src={`https://www.npm06.com/uploads/${selectedFile}`} alt="Fichier" style={{ maxWidth: '100%' }} />
              )
            ) : (
              <video controls style={{ maxWidth: '100%' }}>
                <source src={`https://www.npm06.com/uploads/${selectedFile}`} type="video/mp4" />
                Votre navigateur ne supporte pas la lecture de vidéo.
              </video>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default EventManager;
