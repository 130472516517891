import { useState } from 'react'; 
import Modal from 'react-modal';
import ProductShowcase from './ProductShowcase';
import TestimonialSection from './TestimonialSection';
import StepsSection from './StepsSection';
import CallToAction from './CallToAction';
import '../scss/SublimationServicePage.scss'; 

Modal.setAppElement('#root'); 

const SublimationServicePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [support, setSupport] = useState('');
  const [textToPrint, setTextToPrint] = useState('');
  const [image, setImage] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [recapVisible, setRecapVisible] = useState(false);
  const [order, setOrder] = useState(null);

  const supports = ['T-shirt', 'Mug', 'Coque de téléphone', 'Tapis de souris'];

  // Ouvrir/fermer le modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Gérer l'upload de l'image
  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };

  // Soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      support,
      textToPrint,
      image,
      quantity,
    };
    setOrder(formData);
    setRecapVisible(true);
  };

  // Confirmation de la commande
  const confirmOrder = () => {
    console.log('Commande confirmée :', order);
    setRecapVisible(false);
    setIsModalOpen(false);
    alert('Commande ajoutée au panier');
  };

  // Produits spécifiques pour la sublimation
  const productsSublimation = [
    { name: 'T-shirt Personnalisé', description: 'Créez votre t-shirt unique avec un design personnalisé.', image: '/images/tshirt-sublimation.jpg' },
    { name: 'Mug Personnalisé', description: 'Personnalisez vos mugs pour offrir ou pour vous-même.', image: '/images/mug-sublimation.jpg' },
    { name: 'Coque Téléphone', description: 'Créez une coque de téléphone personnalisée.', image: '/images/coque-sublimation.jpg' },
  ];

  // Témoignages clients spécifiques pour la sublimation
  const testimonialsSublimation = [
    { quote: 'J\'adore mon nouveau t-shirt personnalisé !', author: 'Lucie Martin' },
    { quote: 'Le mug personnalisé est un excellent cadeau.', author: 'Paul Lefebvre' },
    { quote: 'Super expérience, mes coques de téléphone sont magnifiques.', author: 'Sophie Durant' },
  ];

  // Étapes spécifiques pour la sublimation
  const stepsSublimation = [
    'Choisissez un support (t-shirt, mug, etc.).',
    'Ajoutez un texte ou un design personnalisé.',
    'Téléchargez votre image si vous le souhaitez.',
    'Recevez votre produit en quelques jours.',
  ];

  return (
    <div className="sublimation-service-page">
      <header className="service-header">
        <h1>Personnalisez vos objets avec notre service de sublimation !</h1>
        <p>Créez des articles uniques à votre image grâce à la sublimation : t-shirts, mugs, coques de téléphone et bien plus encore.</p>
      </header>

      <StepsSection steps={stepsSublimation} serviceType="Sublimation" />
      <ProductShowcase products={productsSublimation} serviceType="Sublimation" />
      <TestimonialSection testimonials={testimonialsSublimation} serviceType="Sublimation" />
      <CallToAction serviceType="Sublimation" onClick={toggleModal} /> {/* Ajout du lien pour ouvrir le modal */}

      <footer className="service-footer">
        <p>Livraison rapide partout en France | Garantie satisfaction | Support client 7j/7</p>
      </footer>

      {/* Modal de personnalisation */}
      <Modal isOpen={isModalOpen} onRequestClose={toggleModal} className="order-modal" overlayClassName="modal-overlay">
        {!recapVisible ? (
          <form onSubmit={handleSubmit} className="order-form">
            <h2>Personnaliser votre produit</h2>

            <label htmlFor="support">Choisissez votre support :</label>
            <select id="support" value={support} onChange={(e) => setSupport(e.target.value)} required>
              <option value="">Sélectionnez un support</option>
              {supports.map((s, index) => (
                <option key={index} value={s}>
                  {s}
                </option>
              ))}
            </select>

            <label htmlFor="textToPrint">Texte à imprimer (facultatif) :</label>
            <textarea
              id="textToPrint"
              value={textToPrint}
              onChange={(e) => setTextToPrint(e.target.value)}
              placeholder="Entrez votre texte ici"
            ></textarea>

            <label htmlFor="imageUpload">Uploader une image (facultatif) :</label>
            <input type="file" id="imageUpload" accept="image/*" onChange={handleImageUpload} />

            <label htmlFor="quantity">Quantité :</label>
            <input
              type="number"
              id="quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              min="1"
              max="100"
              required
            />

            <button type="submit" className="submit-button">
              Valider
            </button>
          </form>
        ) : (
          <div className="order-recap">
            <h2>Récapitulatif de la commande</h2>
            <p><strong>Support :</strong> {order.support}</p>
            <p><strong>Texte à imprimer :</strong> {order.textToPrint || 'Aucun texte'}</p>
            <p><strong>Quantité :</strong> {order.quantity}</p>
            {order.image && <p><strong>Image :</strong> {order.image.name}</p>}

            <button onClick={confirmOrder} className="confirm-button">
              Confirmer la commande
            </button>
            <button onClick={() => setRecapVisible(false)} className="edit-button">
              Modifier la commande
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SublimationServicePage;
