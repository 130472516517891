import { useState, useEffect, useCallback } from 'react';

function SliderManager() {
  const [images, setImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [formData, setFormData] = useState({
    image_url: '',
    description: '',  // Utilisation de description au lieu de alt_text
  });
  const [token, setToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState(''); // Gestion des erreurs

  // Récupérer le token
  useEffect(() => {
    const localToken = localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
    if (localToken) {
      setToken(localToken);
    } else {
      console.error('Token non disponible');
    }
  }, []);

  // Fonction pour récupérer les images
  const fetchImages = useCallback(async () => {
    if (!token) return;
    try {
      const response = await fetch('https://npm06.com/api/sliderimages', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok: ' + response.statusText);
      }
      const data = await response.json();
      setImages(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des images:', error);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchImages();
    }
  }, [token, fetchImages]);

  // Gérer les changements de fichiers
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, image_url: file });
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  // Ajouter une nouvelle image avec gestion d'erreur
  const handleAddImage = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Réinitialiser le message d'erreur
  
    if (!formData.image_url) {
      setErrorMessage("Veuillez sélectionner une image.");
      return;
    }
    if (!formData.description) {
      setErrorMessage("Veuillez entrer une description.");
      return;
    }
  
    // Préparer les données à envoyer
    const formDataToSend = new FormData();
    formDataToSend.append('image', formData.image_url);  // 'image' correspond au nom du champ dans le backend
    formDataToSend.append('description', formData.description);
  
    try {
      const response = await fetch('https://npm06.com/api/sliderimages', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}` // Authentification avec le token
        },
        body: formDataToSend,  // FormData doit être utilisé ici pour gérer le fichier
      });
  
      // Vérifier la réponse du serveur
      if (!response.ok) {
        const errorData = await response.json();  // Obtenir les détails de l'erreur du serveur
        throw new Error(errorData.message || 'Erreur lors de l\'ajout de l\'image.');
      }
  
      await response.json();
      fetchImages();  // Rafraîchir la liste après l'ajout
      closeModal();  // Fermer la modal après succès
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'image:', error);
      setErrorMessage(error.message || 'Une erreur est survenue lors de l\'ajout de l\'image.');
    }
  };

  // Modifier une image existante
  const handleEditImage = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Réinitialiser le message d'erreur

    const formDataToSend = new FormData();
    formDataToSend.append('image', formData.image_url);
    formDataToSend.append('description', formData.description);  // Utilisation de description

    try {
      const response = await fetch(`https://npm06.com/api/sliderimages/${selectedImageId}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSend,
      });
      if (!response.ok) {
        throw new Error('Erreur lors de la modification de l\'image');
      }

      await response.json();
      fetchImages(); // Rafraîchir la liste après modification
      closeModal(); // Fermer la modal après succès
    } catch (error) {
      console.error('Erreur lors de la modification de l\'image:', error);
      setErrorMessage('Une erreur est survenue lors de la modification de l\'image.');
    }
  };

  // Ouvrir la modal pour ajouter ou modifier une image
  const openModal = (image = null) => {
    setErrorMessage(''); // Réinitialiser l'erreur lorsque la modal s'ouvre
    if (image) {
      setIsEditMode(true);
      setFormData({ description: image.description, image_url: '' });
      setSelectedImageId(image.id);
      setPreviewImage(`https://npm06.com/uploads/${image.image_url}`);
    } else {
      setIsEditMode(false);
      setFormData({ image_url: '', description: '' });
      setPreviewImage(null);
    }
    setIsModalOpen(true);
  };

  // Fermer la modal
  const closeModal = () => {
    setIsModalOpen(false);
    setPreviewImage(null);
    setFormData({ image_url: '', description: '' });
    setIsEditMode(false);
    setSelectedImageId(null);
    setErrorMessage(''); // Réinitialiser le message d'erreur en fermant la modal
  };

  // Supprimer une image
  const handleDeleteImage = async (id) => {
    try {
      const response = await fetch(`https://npm06.com/api/sliderimages/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Erreur lors de la suppression de l\'image');
      }
      fetchImages(); // Rafraîchir la liste après suppression
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'image:', error);
    }
  };

  return (
    <div className="slider-manager">
      <h2>Gestion du Slider</h2>

      <button type="button" onClick={() => openModal()}>Ajouter une Image</button>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <form onSubmit={isEditMode ? handleEditImage : handleAddImage}>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              {previewImage && (
                <img src={previewImage} alt="Preview" style={{ width: '100px', height: '100px' }} />
              )}
              <input type="file" name="image_url" onChange={handleFileChange} />
              <input
                type="text"
                name="description"  // Utiliser description
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                placeholder="Description"
              />
              <button type="submit">{isEditMode ? 'Modifier' : 'Ajouter'}</button>
            </form>
          </div>
        </div>
      )}

      <div>
        <h2>Liste des images</h2>
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Description</th>
              <th>Modifier</th>
              <th>Supprimer</th>
            </tr>
          </thead>
          <tbody>
            {images.map((image) => (
              <tr key={image.id}>
                <td>
                  <img
                    src={`https://npm06.com/uploads/${image.image_url}`}
                    alt={image.description}
                    style={{ width: '50px' }}
                  />
                </td>
                <td>{image.description}</td>
                <td>
                  <button onClick={() => openModal(image)}>Modifier</button>
                </td>
                <td>
                  <button onClick={() => handleDeleteImage(image.id)}>Supprimer</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SliderManager;
