import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { Link, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesome
import { faBars } from '@fortawesome/free-solid-svg-icons'; // Import de l'icône du menu
import '../scss/Annonces.scss';
import axios from 'axios';

const Annonces = () => {
  const { addToCart, setAnnonces } = useContext(AppContext); // Assurez-vous que addToCart est défini correctement
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState(''); 
  const [filteredAnnonces, setFilteredAnnonces] = useState([]); 
  const navigate = useNavigate();

  // Récupérer les annonces approuvées
  useEffect(() => {
    const fetchApprovedAnnonces = async () => {
      try {
        const response = await axios.get('https://www.npm06.com/api/annonces/approved');
        setAnnonces(response.data);
        setFilteredAnnonces(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des annonces approuvées:', error);
      }
    };

    fetchApprovedAnnonces();
  }, [setAnnonces]);

  // Gestion du tri par prix
  const handleSortChange = (e) => {
    const sortValue = e.target.value;
    setSortOrder(sortValue);

    const sortedAnnonces = [...filteredAnnonces];
    if (sortValue === 'asc') {
      sortedAnnonces.sort((a, b) => a.price - b.price);
    } else if (sortValue === 'desc') {
      sortedAnnonces.sort((a, b) => b.price - a.price);
    }
    setFilteredAnnonces(sortedAnnonces);
  };

  // Fonction pour ajouter une annonce au panier
  const handleAddToCart = (annonce) => {
    const productToAdd = {
      product_id: annonce.id,
      name: annonce.title,
      price: annonce.price,
      quantity: 1,
      image_url: annonce.image_url
    };

    addToCart(productToAdd); // Ajoute l'annonce dans le panier
    setIsAlertOpen(true); // Ouvre l'alerte
  };

  // Fonction pour naviguer vers la page du panier
  const handleGoToCart = () => {
    setIsAlertOpen(false);
    navigate('/cart');
  };

  // Fonction pour gérer l'ouverture/la fermeture de la SidebarCat
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="annonces-page">
      <FontAwesomeIcon
        icon={faBars}
        className="toggle-sidebar"
        onClick={toggleSidebar}
        size="2x"
      />
   
      <div className="annonces-section">
        <h2>Nos Annonces</h2>

        {/* Filtres de tri par prix */}
        <div className="filter-section">
          <label htmlFor="sortOrder">Trier par prix : </label>
          <select id="sortOrder" value={sortOrder} onChange={handleSortChange}>
            <option value="">Sélectionnez</option>
            <option value="asc">Prix croissant</option>
            <option value="desc">Prix décroissant</option>
          </select>
        </div>

        <div className="annonces-grid">
          {Array.isArray(filteredAnnonces) && filteredAnnonces.length > 0 ? (
            filteredAnnonces.map((annonce) => (
              <div key={annonce.id} className="annonce-card">
                <img
                  src={`https://npm06.com/uploads/${annonce.image_url}`}
                  alt={annonce.title}
                  style={{ width: '150px', height: '150px' }}
                />
                <h3>{annonce.title}</h3>
                <p>{annonce.description}</p>
                <p>{annonce.price} €</p>

                <Link to={`/annonceDetail/${annonce.id}`} className="detail-button">
                  Voir Détail
                </Link>
                <button className="add-to-cart-button" onClick={() => handleAddToCart(annonce)}>
                  Ajouter au Panier
                </button>
              </div>
            ))
          ) : (
            <p>Aucune annonce disponible pour le moment.</p>
          )}
        </div>
      </div>

      {/* Alerte de confirmation pour l'ajout au panier */}
      {isAlertOpen && (
        <div className="alert-box">
          <div className="alert-content">
            <p>Annonce ajoutée au panier avec succès !</p>
            <button onClick={() => setIsAlertOpen(false)}>Continuer les achats</button>
            <button onClick={handleGoToCart}>Voir le Panier</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Annonces;
