// src/context/Store_redux/reducers/index.js

import { combineReducers } from 'redux';
import someReducer from './someReducer';
import anotherReducer from './anotherReducer';
import authReducer from './authReducer'; // Importez votre reducer

const rootReducer = combineReducers({ auth: authReducer,
  someState: someReducer,
  anotherState: anotherReducer,
  // Ajoutez d'autres reducers ici
});

export default rootReducer;
