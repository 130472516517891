import { useState } from 'react';
import '../scss/VideoEditing.scss'; // Assurez-vous d'avoir un fichier SCSS correspondant

const VideoEditing = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    description: '',
    videoFile: null,
    imageFile: null,
  });

  const openPopup = (service) => {
    setSelectedService(service);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFormData({
      nom: '',
      prenom: '',
      email: '',
      telephone: '',
      description: '',
      videoFile: null,
      imageFile: null,
    });
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ajouter ici la logique de traitement de formulaire (envoi vers un serveur, etc.)
    alert('Commande passée pour ' + selectedService);
    closePopup();
  };

  return (
    <div className="video-editing">
      <h2>Nos prestations de montage vidéo</h2>
      <div className="services">
        <div className="service-card" onClick={() => openPopup('Montage Basique')}>
          <h3>Montage Basique</h3>
          <p>Pour des montages simples et efficaces.</p>
        </div>
        <div className="service-card" onClick={() => openPopup('Montage Avancé')}>
          <h3>Montage Avancé</h3>
          <p>Pour des montages plus complexes avec effets.</p>
        </div>
        <div className="service-card" onClick={() => openPopup('Montage Professionnel')}>
          <h3>Montage Professionnel</h3>
          <p>Pour des montages de qualité professionnelle.</p>
        </div>
      </div>

      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h3>{selectedService}</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Nom:</label>
                <input
                  type="text"
                  name="nom"
                  value={formData.nom}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Prénom:</label>
                <input
                  type="text"
                  name="prenom"
                  value={formData.prenom}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Téléphone:</label>
                <input
                  type="tel"
                  name="telephone"
                  value={formData.telephone}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Description du projet:</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className="form-group">
                <label>Fichier Vidéo:</label>
                <input
                  type="file"
                  name="videoFile"
                  accept="video/*"
                  onChange={handleFileChange}
                />
              </div>
              <div className="form-group">
                <label>Fichiers Image:</label>
                <input
                  type="file"
                  name="imageFile"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              <div className="form-actions">
                <button type="button" onClick={closePopup} className="btn-secondary">
                  Effacer le formulaire
                </button>
                <button type="submit" className="btn-primary">
                  Commander
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoEditing;
