

const DashboardFooter = () => {
  return (
    <footer>
      <p>© 2024 Npm Run Dev. Tous droits réservés.</p>
      {/* Ajoutez ici d'autres éléments spécifiques au footer du dashboard */}
    </footer>
  );
};

export default DashboardFooter;
