import { useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import '../scss/WebmasterFullStack.scss'; 

const WebmasterFullStack = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isDateErrorModalOpen, setIsDateErrorModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedDetail, setSelectedDetail] = useState('');
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    description: '',
    date_evenement: '',
    type_service: '',
    fichier_video: '',
    fichier_image: '',
    user_id: null,
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const dateInputRef = useRef(null);

  const validateDate = () => {
    const today = new Date();
    const minDate = new Date();
    minDate.setDate(today.getDate() + 15);

    const eventDate = new Date(formData.date_evenement);
    return eventDate >= minDate;
  };

  const openPopup = (service) => {
    setSelectedService(service);
    setFormData({ ...formData, type_service: service });
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFormData({
      nom: '',
      prenom: '',
      email: '',
      telephone: '',
      description: '',
      date_evenement: '',
      type_service: '',
      fichier_video: '',
      fichier_image: '',
      user_id: null,
    });
    setMessage('');
    setError(false);
  };

  const openDetailModal = (service) => {
    setSelectedService(service);
    let detailContent = '';

    switch (service) {
      case 'Développement Front-End':
        detailContent = `
          Offrez une expérience moderne et immersive à vos utilisateurs avec notre service de développement Front-End.
          Nous créons des interfaces dynamiques et réactives en utilisant des technologies avancées comme React et Vue.js. 
          Créez un site qui capte l'attention et fait passer votre message efficacement.
        `;
        break;
      case 'Développement Back-End':
        detailContent = `
          Un back-end solide est la clé d'une infrastructure fiable. Nous développons des systèmes puissants et évolutifs 
          avec Node.js et Python pour répondre à vos besoins spécifiques en gestion de données, API, et logique serveur.
        `;
        break;
      case 'SEO & Performance':
        detailContent = `
          Boostez la visibilité de votre site avec notre service SEO & Performance. Augmentez votre classement Google et 
          optimisez la vitesse de votre site pour garantir une expérience utilisateur exceptionnelle et maximiser votre potentiel de conversion.
        `;
        break;
      default:
        detailContent = 'Détails indisponibles pour ce service.';
    }

    setSelectedDetail(detailContent);
    setIsDetailModalOpen(true);
  };

  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0]?.name || '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateDate()) {
      setIsDateErrorModalOpen(true);
      return;
    }

    try {
      const requestData = {
        nom: formData.nom,
        prenom: formData.prenom,
        email: formData.email,
        telephone: formData.telephone,
        description: formData.description,
        date_evenement: formData.date_evenement,
        type_service: formData.type_service,
        fichier_video: formData.fichier_video,
        fichier_image: formData.fichier_image,
        user_id: formData.user_id,
      };

      const response = await axios.post('https://www.npm06.com/api/demande_devis', requestData);

      if (response.status >= 200 && response.status < 300) {
        setMessage('Votre demande a bien été envoyée ! Un email de confirmation a été envoyé à votre adresse.');
        setIsConfirmationModalOpen(true);
      } else {
        setMessage('Une erreur est survenue lors de la demande de devis. Veuillez réessayer.');
        setError(true);
      }
    } catch (error) {
      setMessage('Une erreur est survenue. Veuillez réessayer.');
      setError(true);
    }
  };

  const handleConfirmationClose = () => {
    setIsConfirmationModalOpen(false);
    navigate('/');
  };

  const handleDateErrorClose = () => {
    setIsDateErrorModalOpen(false);
  };

  const openDatePicker = () => {
    if (dateInputRef.current) {
      dateInputRef.current.click();
    }
  };

  return (
    <div className="webmaster-fullstack">
      <h2>Services Webmaster Full Stack</h2>
      <div className="services">
      <div className="service-card">
  <h3>Développement Front-End</h3>
  <p>Création d'interfaces utilisateur modernes et réactives.</p>
  <div className="service-actions">
    <a href="#" onClick={(e) => { e.preventDefault(); openDetailModal('Développement Front-End'); }}>Détails</a>
    <a href="#" onClick={(e) => { e.preventDefault(); openPopup('Développement Front-End'); }} className="devis-link">
      Demander un devis
    </a>
  </div>
</div>
<div className="service-card">
  <h3>Développement Back-End</h3>
  <p>Gestion des bases de données et logiques serveur.</p>
  <div className="service-actions">
    <a href="#" onClick={(e) => { e.preventDefault(); openDetailModal('Développement Back-End'); }}>Détails</a>
    <a href="#" onClick={(e) => { e.preventDefault(); openPopup('Développement Back-End'); }} className="devis-link">
      Demander un devis
    </a>
  </div>
</div>
<div className="service-card">
  <h3>SEO & Performance</h3>
  <p>Optimisation pour les moteurs de recherche et la performance.</p>
  <div className="service-actions">
    <a href="#" onClick={(e) => { e.preventDefault(); openDetailModal('SEO & Performance'); }}>Détails</a>
    <a href="#" onClick={(e) => { e.preventDefault(); openPopup('SEO & Performance'); }} className="devis-link">
      Demander un devis
    </a>
  </div>
</div>
      </div>

      {isDetailModalOpen && (
        <div className="modal2">
          <div className="modal-content2">
            <h3>{selectedService}</h3>
            <p>{selectedDetail}</p>
            <button onClick={closeDetailModal} className="btn-primary">
              OK
            </button>
          </div>
        </div>
      )}

      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h3>{selectedService}</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Nom:</label>
                <input type="text" name="nom" value={formData.nom} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Prénom:</label>
                <input type="text" name="prenom" value={formData.prenom} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Email:</label>
                <input type="email" name="email" value={formData.email} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Téléphone:</label>
                <input type="tel" name="telephone" value={formData.telephone} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Description du projet:</label>
                <textarea name="description" value={formData.description} onChange={handleInputChange}></textarea>
              </div>
              <div className="form-group">
                <label>Quand pouvons nous vous contacter?:</label>
                <div className="date-picker-container">
                  <input
                    type="date"
                    name="date_evenement"
                    value={formData.date_evenement}
                    onChange={handleInputChange}
                    ref={dateInputRef}
                    required
                  />
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="calendar-icon"
                    onClick={openDatePicker}
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Vidéo (fichier vidéo):</label>
                <input type="file" name="fichier_video" onChange={handleFileChange} />
              </div>
              <div className="form-group">
                <label>Image (fichier image):</label>
                <input type="file" name="fichier_image" onChange={handleFileChange} />
              </div>
              <div className="form-group">
                <label>Type de service:</label>
                <input type="text" name="type_service" value={formData.type_service} readOnly />
              </div>
              <div className="form-actions">
                <button type="button" onClick={closePopup} className="btn-secondary">
                  Effacer le formulaire
                </button>
                <button type="submit" className="btn-primary">
                  Demander un Devis
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isConfirmationModalOpen && (
        <div className="modal2">
          <div className="modal-content2">
            <h3>Confirmation</h3>
            <p>Votre demande de devis a bien été envoyée.</p>
            <p>Un email de confirmation a été envoyé à votre adresse.</p>
            <button onClick={handleConfirmationClose} className="btn-primary">
              OK
            </button>
          </div>
        </div>
      )}

      {isDateErrorModalOpen && (
        <div className="modal2">
          <div className="modal-content2">
            <h3>Erreur de date</h3>
            <p>La date de l'événement doit être au minimum dans 15 jours.</p>
            <p>Si c'est urgent, merci de nous contacter par téléphone.</p>
            <button onClick={handleDateErrorClose} className="btn-primary">
              OK
            </button>
          </div>
        </div>
      )}

      {message && <p className={`confirmation-message ${error ? 'error' : ''}`}>{message}</p>}
    </div>
  );
};

export default WebmasterFullStack;
