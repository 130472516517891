import { useState, useContext } from 'react';
import '../scss/ConfirmCommande.scss';
import { AppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ConfirmCommande = () => {
  const { user, cart } = useContext(AppContext); // Suppression de setUser et userId
  const [nom, setNom] = useState(user?.username || ''); // Pré-remplir avec les données utilisateur si disponibles
  const [prenom, setPrenom] = useState(user?.usersurname || '');
  const [adresse, setAdresse] = useState(user?.adresse || '');
  const [codePostal, setCodePostal] = useState(user?.code_postal || '');
  const [ville, setVille] = useState(user?.ville || '');
  const [pays] = useState(user?.pays || 'France');
  const [email, setEmail] = useState(user?.email || '');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Calculer le prix total TTC
  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => {
      const quantity = item.quantity || 1;
      return total + parseFloat(item.price) * quantity;
    }, 0).toFixed(2);
  };

  // Valider et confirmer la commande
  const handleConfirmOrder = () => {
    if (!isConfirmed) {
      setError('Vous devez accepter les conditions pour confirmer la commande.');
      return;
    }

    if (!nom || !prenom || !adresse || !codePostal || !ville || !pays || !email) {
      setError('Tous les champs doivent être remplis.');
      return;
    }

    const orderData = {
      user_id: user?.id,
      items: cart,
      total_price: calculateTotalPrice(),
      delivery_price: 29.0,
      total_ttc: (parseFloat(calculateTotalPrice()) + 29.0).toFixed(2),
      customer_info: { nom, prenom, adresse, codePostal, ville, pays, email },
      status: 'pending',
    };

    // Sauvegarder la commande dans le localStorage pour le traitement du paiement
    localStorage.setItem('orderData', JSON.stringify(orderData));

    // Rediriger vers la page de paiement
    navigate('/payment');
  };

  return (
    <div className="confirm-commande-page">
      <div className="content-wrapper">
        <div className="recap-section">
          <h2>Récapitulatif de votre commande</h2>
          <div className="cart-items">
            {cart.map((item, index) => (
              <div key={index} className="cart-item">
                <img
                  src={item.image_url ? `https://npm06.com/uploads/${item.image_url}` : '/images/fallback-image.jpg'}
                  alt={item.name}
                  className="item-image"
                />
                <div className="item-details">
                  <p className="item-name">{item.name}</p>
                  <p className="item-price">{parseFloat(item.price).toFixed(2)} €</p>
                  <p>Quantité: {item.quantity}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="form-section">
          <form className="confirm-form">
            <div className="form-group">
              <input
                type="text"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                required
                placeholder="Nom"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
                required
                placeholder="Prénom"
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                value={adresse}
                onChange={(e) => setAdresse(e.target.value)}
                required
                placeholder="Adresse"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                value={codePostal}
                onChange={(e) => setCodePostal(e.target.value)}
                required
                placeholder="Code Postal"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                value={ville}
                onChange={(e) => setVille(e.target.value)}
                required
                placeholder="Ville"
              />
            </div>
            <div className="form-group">
              <label>
                <input type="checkbox" checked={isConfirmed} onChange={() => setIsConfirmed(!isConfirmed)} />
                J'accepte les conditions générales de vente
              </label>
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="button" onClick={handleConfirmOrder}>Confirmer la commande</button>
          </form>
        </div>
      </div>
    </div>
  );
};

ConfirmCommande.propTypes = {
  userId: PropTypes.number,
};

export default ConfirmCommande;
