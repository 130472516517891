import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import '../scss/CustomSongService.scss';
import fond_music from '../assets/fond_music.mp4'; // Exemple de vidéo
import Nuit_Synthétique from '../assets/Nuit_Synthétique.mp3'; // Exemple d'audio
import Loin_des_Ombres from '../assets/Loin_des_Ombres.mp3'; // Exemple d'audio

const CustomSongService = () => {
  const { isLoggedIn } = useContext(AppContext);
  const navigate = useNavigate();

  // Redirige vers la page de personnalisation si l'utilisateur est connecté
  const handleGoToCustomSongPage = () => {
    if (isLoggedIn) {
      navigate('/customsongpage');
    } else {
      navigate('/login');
    }
  };

  return (
    <section className="custom-song-service">
      <video autoPlay loop muted id="video-background">
        <source src={fond_music} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="content-wrapper">
        {/* Texte accrocheur à gauche */}
        <div className="catchy-text-box">
          <h2>🎵 Créez une Chanson Personnalisée Inoubliable ! 🎤</h2>
          <p>
            Imaginez une chanson qui capture parfaitement vos émotions, vos idées, ou même un moment spécial de votre vie.
            Que ce soit pour une occasion unique, un événement familial, ou un simple cadeau original, nous sommes là pour transformer vos idées en mélodie.
          </p>
          <p>
            Personnalisez chaque détail :
            <br /> - Fournissez-nous un <strong>texte personnalisé</strong> ou laissez-nous vous surprendre avec une création unique.
            <br /> - Ajoutez un <strong>clip vidéo</strong> pour rendre ce moment encore plus spécial.
          </p>
          <p>
            <strong>Nos Tarifs :</strong>
            <br /> - Chanson personnalisée : 10€
            <br /> - Clip surprise : +20€
            <br /> - Clip personnalisé : +40€
          </p>
          <p>
            Créez une œuvre musicale qui restera dans vos souvenirs à jamais ! Prêt(e) à commencer ? Cliquez ci-dessous pour lancer votre projet musical.
          </p>
          {/* Bouton pour personnaliser la chanson */}
          <button className="btn-primary" onClick={handleGoToCustomSongPage}>
            Personnaliser une chanson
          </button>
        </div>

        {/* Exemples audio à droite */}
        <div className="audio-examples">
          <h3>Exemples de Chansons Personnalisées</h3>
          <div className="audio-grid">
            <div className="audio-item">
              <audio controls>
                <source src={Loin_des_Ombres} type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
              <p>Chanson Pop Exemple</p>
            </div>
            <div className="audio-item">
              <audio controls>
                <source src={Nuit_Synthétique} type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
              <p>Chanson Jazz Exemple</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomSongService;
