import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../scss/Profile.scss';
import axios from 'axios';

const Profile = ({ userId, updateUser }) => {
  const [formData, setFormData] = useState({
    username: '',
    usersurname: '',
    email: '',
    adresse: '',
    code_postal: '',
    ville: '',
    pays: '',
    phone: '',
    image_url: '',
    newPassword: '',
    confirmNewPassword: '',
    role: ''
  });

  const [previewImage, setPreviewImage] = useState(null);
  const [errors, setErrors] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  // Fonction pour obtenir le token depuis localStorage ou sessionStorage
  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  // Récupération des données utilisateur
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = getToken();
        if (!token) throw new Error('Token non trouvé, veuillez vous reconnecter.');

        const response = await axios.get(`https://npm06.com/api/users/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status !== 200) {
          throw new Error('Erreur lors de la récupération des données utilisateur.');
        }

        const data = response.data;
        setFormData({
          username: data.username || '',
          usersurname: data.usersurname || '',
          email: data.email || '',
          adresse: data.adresse || '',
          code_postal: data.code_postal || '',
          ville: data.ville || '',
          pays: data.pays || '',
          phone: data.phone || '',
          image_url: data.image_url || '',
          newPassword: '',
          confirmNewPassword: '',
          role: data.role || '',
        });
        setPreviewImage(data.image_url || '');
      } catch (error) {
        setErrors(error.response?.data?.message || 'Erreur lors de la récupération des données utilisateur.');
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchUserData();
    } else {
      setErrors('ID utilisateur non fourni.');
      setLoading(false);
    }
  }, [userId]);

  // Soumettre le formulaire de mise à jour du profil
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors('');
    setSuccessMessage('');

    if (formData.newPassword && formData.newPassword !== formData.confirmNewPassword) {
      setErrors('Les nouveaux mots de passe ne correspondent pas.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('username', formData.username);
    formDataToSend.append('usersurname', formData.usersurname);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('adresse', formData.adresse);
    formDataToSend.append('code_postal', formData.code_postal);
    formDataToSend.append('ville', formData.ville);
    formDataToSend.append('pays', formData.pays);
    formDataToSend.append('phone', formData.phone);

    if (formData.newPassword) {
      formDataToSend.append('password', formData.newPassword);
    }

    try {
      const token = getToken();
      const response = await axios.put(`https://npm06.com/api/update/${userId}`, formDataToSend, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status !== 200) {
        throw new Error('Erreur lors de la mise à jour du profil.');
      }

      updateUser(response.data.user);
      setSuccessMessage('Profil mis à jour avec succès.');
      setIsEditing(false);
    } catch (error) {
      setErrors(error.response?.data?.message || 'Une erreur est survenue. Veuillez réessayer.');
    }
  };

  if (!userId) {
    return <div>Veuillez vous connecter pour voir cette page.</div>;
  }

  if (loading) {
    return <p>Chargement des informations utilisateur...</p>;
  }

  return (
    <div className="profile-page">
      <div className="profile-actions">
        <button
          onClick={() => setIsEditing(!isEditing)}
          className="edit-button"
        >
          {isEditing ? 'Annuler' : 'Éditer le Profil'}
        </button>
      </div>

      {isEditing ? (
        <div className="profile-edit-container">
          <form className="profile-form" onSubmit={handleSubmit}>
            <div className="form-columns">
              <div className="left-column">
                <div className="form-group">
                  <label htmlFor="username">Nom</label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="usersurname">Prénom</label>
                  <input
                    type="text"
                    id="usersurname"
                    name="usersurname"
                    value={formData.usersurname}
                    onChange={(e) => setFormData({ ...formData, usersurname: e.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="adresse">Adresse</label>
                  <input
                    type="text"
                    id="adresse"
                    name="adresse"
                    value={formData.adresse}
                    onChange={(e) => setFormData({ ...formData, adresse: e.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="code_postal">Code Postal</label>
                  <input
                    type="text"
                    id="code_postal"
                    name="code_postal"
                    value={formData.code_postal}
                    onChange={(e) => setFormData({ ...formData, code_postal: e.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="ville">Ville</label>
                  <input
                    type="text"
                    id="ville"
                    name="ville"
                    value={formData.ville}
                    onChange={(e) => setFormData({ ...formData, ville: e.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="pays">Pays</label>
                  <input
                    type="text"
                    id="pays"
                    name="pays"
                    value={formData.pays}
                    onChange={(e) => setFormData({ ...formData, pays: e.target.value })}
                    required
                  />
                </div>
              </div>

              <div className="right-column">
                <div className="form-group">
                  <label htmlFor="phone">Téléphone</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="newPassword">Nouveau mot de passe</label>
                  <input
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="confirmNewPassword">Confirmer le nouveau mot de passe</label>
                  <input
                    type="password"
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    value={formData.confirmNewPassword}
                    onChange={(e) => setFormData({ ...formData, confirmNewPassword: e.target.value })}
                  />
                </div>
              </div>
            </div>

            <div className="submit-button-container">
              <button type="submit" className="submit-button">Enregistrer</button>
            </div>
          </form>
        </div>
      ) : (
        <div className="profile-content">
          <h2>Détails du Profil</h2>
          <div className="profile-details">
            <div className="image-container">
              {previewImage && <img src={previewImage} alt="Profile" className="image" />}
            </div>
            <p><strong>Nom:</strong> {formData.username}</p>
            <p><strong>Prénom:</strong> {formData.usersurname}</p>
            <p><strong>Email:</strong> {formData.email}</p>
            <p><strong>Adresse:</strong> {formData.adresse}</p>
            <p><strong>Code Postal:</strong> {formData.code_postal}</p>
            <p><strong>Ville:</strong> {formData.ville}</p>
            <p><strong>Pays:</strong> {formData.pays}</p>
            <p><strong>Téléphone:</strong> {formData.phone}</p>
          </div>
        </div>
      )}

      {errors && <p className="error-message">{errors}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
};

Profile.propTypes = {
  userId: PropTypes.number,
  updateUser: PropTypes.func,
};

export default Profile;
