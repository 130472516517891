import React from 'react';
import PropTypes from 'prop-types';
import '../scss/TestimonialSection.scss';

const TestimonialSection = ({ testimonials, serviceType }) => {
  return (
    <div className="testimonial-section">
      <h2>Avis Clients sur {serviceType}</h2>
      <div className="testimonials">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <p>"{testimonial.quote}"</p>
            <strong>- {testimonial.author}</strong>
          </div>
        ))}
      </div>
    </div>
  );
};

TestimonialSection.propTypes = {
  testimonials: PropTypes.array.isRequired,
  serviceType: PropTypes.string.isRequired,
};

export default TestimonialSection;
