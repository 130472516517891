
import '../scss/PrivacyPolicyPopup.scss';

const PrivacyPolicyPopup = ({ onClose }) => {
  return (
    <div className="privacy-policy-popup">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Politique de confidentialité</h2>
        <p>
          Votre vie privée est importante pour nous. Cette politique de confidentialité explique
          quelles données personnelles nous collectons, pourquoi nous les collectons, et comment
          nous les utilisons.
        </p>
        <p>
          <strong>Données collectées :</strong> Nous collectons des informations telles que votre
          nom, adresse email, et autres informations nécessaires pour améliorer votre expérience
          sur notre site.
        </p>
        <p>
          <strong>Utilisation des données :</strong> Vos informations sont utilisées pour vous
          fournir les services demandés, améliorer nos services, et personnaliser votre expérience.
        </p>
        <p>
          <strong>Partage des données :</strong> Nous ne partageons pas vos informations avec des
          tiers sans votre consentement, sauf si requis par la loi.
        </p>
        <p>
          Pour en savoir plus, veuillez consulter notre{' '}
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            politique de confidentialité complète
          </a>
          .
        </p>
        <button className="accept-button" onClick={onClose}>
          J'accepte
        </button>
      </div>
    </div>
  );
};

export default PrivacyPolicyPopup;
