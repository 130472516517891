import React from 'react';

const ConditionsGeneralesVente = () => {
  return (
    <div className="cgv-container">
      <h1>Conditions Générales de Vente</h1>
      <h2>1. Introduction</h2>
      <p>
        Les présentes Conditions Générales de Vente (CGV) régissent les relations
        contractuelles entre l'entreprise <strong>[Nom de l'Entreprise]</strong>, dont le siège
        social est situé à [Adresse], immatriculée sous le numéro [Numéro
        d'Immatriculation], ci-après dénommée "l'Entreprise", et toute personne
        physique ou morale souhaitant procéder à un achat via le site internet
        <strong> [Nom du Site]</strong>, ci-après dénommée "le Client".
      </p>

      <h2>2. Objet</h2>
      <p>
        Les présentes CGV ont pour objet de définir les droits et obligations des
        parties dans le cadre de la vente en ligne de produits ou services proposés
        par l'Entreprise au Client.
      </p>

      <h2>3. Acceptation des Conditions Générales de Vente</h2>
      <p>
        Toute commande passée sur le site <strong>[Nom du Site]</strong> implique l'acceptation
        pleine et entière des présentes CGV. Le Client reconnaît avoir pris
        connaissance des présentes CGV avant de passer commande.
      </p>

      <h2>4. Produits et Services</h2>
      <p>
        Les produits et services proposés à la vente sont ceux qui figurent sur le
        site <strong>[Nom du Site]</strong> au jour de la consultation du site par le Client. Les
        produits sont décrits et présentés avec la plus grande exactitude possible.
        Toutefois, si des erreurs ou omissions ont pu se produire quant à cette
        présentation, la responsabilité de l'Entreprise ne pourrait être engagée.
      </p>

      <h2>5. Tarifs</h2>
      <p>
        Les prix des produits et services sont indiqués en euros (€) toutes taxes
        comprises (TTC). L'Entreprise se réserve le droit de modifier ses prix à tout
        moment, étant entendu que le prix figurant sur le site le jour de la commande
        sera le seul applicable au Client. Les frais de livraison, lorsqu'ils sont
        applicables, sont à la charge du Client et sont indiqués avant la validation
        de la commande.
      </p>

      <h2>6. Commande</h2>
      <p>
        Le Client peut passer commande en ligne sur le site <strong>[Nom du Site]</strong>. La commande
        sera considérée comme valide une fois que le paiement aura été confirmé.
        L'Entreprise se réserve le droit d'annuler ou de refuser toute commande d'un
        Client avec lequel il existerait un litige relatif au paiement d'une commande
        antérieure.
      </p>

      <h2>7. Paiement</h2>
      <p>
        Le paiement des achats s'effectue par carte bancaire, PayPal, ou tout autre
        moyen de paiement accepté sur le site. Le débit est effectué au moment de la
        validation de la commande. L'Entreprise met en œuvre tous les moyens
        nécessaires pour assurer la sécurité et la confidentialité des données
        transmises en ligne.
      </p>

      <h2>8. Livraison</h2>
      <p>
        Les produits sont livrés à l'adresse indiquée par le Client lors de la commande.
        Les délais de livraison sont indiqués sur le site et peuvent varier en fonction
        de la disponibilité des produits et de la destination. L'Entreprise ne saurait
        être tenue responsable des retards de livraison imputables au transporteur.
      </p>

      <h2>9. Droit de Rétractation</h2>
      <p>
        Conformément aux dispositions légales en vigueur, le Client dispose d'un délai
        de 14 jours à compter de la réception des produits pour exercer son droit de
        rétractation sans avoir à justifier de motifs ni à payer de pénalités. Le Client
        devra retourner les produits dans leur état d'origine à ses frais. Les produits
        endommagés ou incomplets ne seront pas remboursés.
      </p>

      <h2>10. Garantie</h2>
      <p>
        Les produits vendus bénéficient de la garantie légale de conformité et de la
        garantie contre les vices cachés. En cas de non-conformité d'un produit vendu,
        il pourra être retourné, échangé ou remboursé. Toutes les réclamations, demandes
        d'échange ou de remboursement doivent s'effectuer par courrier électronique à
        l'adresse <strong>[Email de l'Entreprise]</strong> dans un délai de 30 jours après la livraison.
      </p>

      <h2>11. Responsabilité</h2>
      <p>
        L'Entreprise, dans le processus de vente en ligne, n'est tenue que par une
        obligation de moyens. Sa responsabilité ne pourra être engagée pour un dommage
        résultant de l'utilisation du réseau internet tel que perte de données,
        intrusion, virus, rupture du service, ou autres problèmes involontaires.
      </p>

      <h2>12. Propriété Intellectuelle</h2>
      <p>
        Tous les éléments du site <strong>[Nom du Site]</strong> sont et restent la propriété intellectuelle
        et exclusive de l'Entreprise. Nul n'est autorisé à reproduire, exploiter,
        rediffuser, ou utiliser à quelque titre que ce soit, même partiellement, des
        éléments du site qu'ils soient logiciels, visuels ou sonores.
      </p>

      <h2>13. Données Personnelles</h2>
      <p>
        Les informations collectées par l'Entreprise lors de la commande sont nécessaires
        pour la gestion de la transaction et pourront être communiquées aux partenaires
        contractuels de l'Entreprise intervenant dans le cadre de l'exécution de cette
        commande. Le Client dispose d'un droit d'accès, de modification, de rectification
        et de suppression des données le concernant.
      </p>

      <h2>14. Litiges</h2>
      <p>
        Les présentes CGV sont soumises à la loi française. En cas de litige, une solution
        amiable sera recherchée avant toute action judiciaire. À défaut, le litige sera
        porté devant les tribunaux compétents.
      </p>

      <h2>15. Modifications des Conditions Générales de Vente</h2>
      <p>
        L'Entreprise se réserve le droit de modifier les présentes CGV à tout moment. Les
        nouvelles conditions générales seront applicables à toute commande passée après
        leur publication sur le site <strong>[Nom du Site]</strong>.
      </p>

      <h2>16. Service Client</h2>
      <p>
        Pour toute information ou question, le service client de l'Entreprise est
        disponible par e-mail à l'adresse <strong>[Email de l'Entreprise]</strong> ou par téléphone
        au <strong>[Numéro de Téléphone]</strong>.
      </p>
    </div>
  );
};

export default ConditionsGeneralesVente;
