import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types'; // Importation de PropTypes
import axios from 'axios';
import '../scss/Chatbot.scss';
import robotIcon from '../assets/robot.png'; // Importe l'image du robot

const Chatbot = ({ onResponse, question }) => {
  const [messages, setMessages] = useState([]); // Gère les messages
  const [hasSentMessage, setHasSentMessage] = useState(false); // Pour vérifier si la question a déjà été envoyée

  // Utilisation de useCallback pour mémoriser la fonction sendMessage
  const sendMessage = useCallback(async (msg) => {
    if (!msg) return;

    const newMessages = [...messages, { sender: 'user', text: msg }];
    setMessages(newMessages);

    try {
      // Envoi du message utilisateur à l'API
      const response = await axios.post(
        'https://npm06.com/api/chatbot/',
        { question: msg },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );

      const botMessage = response.data.answer || 'Pas de réponse disponible.';

      // Mise à jour des messages avec la réponse du bot
      const updatedMessages = [...newMessages, { sender: 'bot', text: botMessage }];
      setMessages(updatedMessages);

      // Appelle la fonction de réponse si elle est définie
      if (onResponse) {
        onResponse(botMessage);
      }
    } catch (error) {
      console.error('Erreur avec le chatbot:', error);
      setMessages([...newMessages, { sender: 'bot', text: `Erreur : ${error.message}` }]);
    }
  }, [messages, onResponse]); // Dépendances : messages et onResponse

  // Utilisation du hook useEffect pour envoyer un message lors du chargement si question est fournie
  useEffect(() => {
    if (question && !hasSentMessage) {
      sendMessage(question); // Envoie la question si elle est fournie
      setHasSentMessage(true); // Empêche d'envoyer la question à nouveau
    }
  }, [question, sendMessage, hasSentMessage]); // Dépendances : question, sendMessage, hasSentMessage

  return (
    <div className="chatbot-container">
      <div className="chatbot-header">
        <img src={robotIcon} alt="Robot" className="chatbot-header-icon" />
        Chatbot
      </div>

      <div className="chatbot-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender}`}>
            <p>{msg.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

Chatbot.propTypes = {
  onResponse: PropTypes.func, // onResponse est une fonction facultative
  question: PropTypes.string, // question peut être passée à l'initialisation
};

export default Chatbot;
