// src/context/Store_redux/reducers/anotherReducer.js

// État initial pour ce reducer
const initialState = {
    count: 0,
  };
  
  // Reducer pour gérer les actions liées à "anotherReducer"
  export default function anotherReducer(state = initialState, action) {
    switch (action.type) {
      case 'INCREMENT':
        return {
          ...state,
          count: state.count + 1,
        };
      case 'DECREMENT':
        return {
          ...state,
          count: state.count - 1,
        };
      case 'RESET':
        return {
          ...state,
          count: 0,
        };
      default:
        return state;
    }
  }
  