import { useContext, useState, useEffect } from 'react';
import '../scss/CartSummary.scss';
import { AppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa'; // Icône de suppression

const CartSummary = () => {
  const { cart, setCart, user } = useContext(AppContext); // Assurez-vous que setCart est disponible
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('CartSummary component mounted');
    console.log('User from context:', user);
    console.log('Cart from context:', cart);
  }, [user, cart]);

  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  const handleSendOrderToOrderPage = () => {
    const token = getToken();

    if (!token) {
      toast.error('Aucun token trouvé.');
      return;
    }

    setIsLoading(true);

    const orderData = {
      user_id: user?.user_id,
      total_price: cart.reduce((total, item) => total + parseFloat(item.price) * (item.quantity || 1), 0) + 4.0,
      delivery_price: 4.0,
      items: cart.map((item) => ({
        id: item.id,
        name: item.name || item.title,
        quantity: item.quantity || 1, // Par défaut, 1 si non défini
        price: parseFloat(item.price),
      })),
    };

    navigate('/orderpage', { state: { orderData } });
    setIsLoading(false);
  };

  const handleDecreaseQuantity = (itemId) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === itemId && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const handleIncreaseQuantity = (itemId) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === itemId ? { ...item, quantity: (item.quantity || 1) + 1 } : item
      )
    );
  };

  const handleRemoveItem = (itemId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== itemId));
  };

  if (cart.length === 0) {
    return <p>Votre panier est vide</p>;
  }

  return (
    <div className="cart-summary-page">
      <div className="cart-left">
        <h2>Mon Panier</h2>
        <div className="cart-items">
          {cart.map((item, index) => {
            const image_url = item.image_url
              ? `https://npm06.com/uploads/${item.image_url}`
              : 'https://via.placeholder.com/150x150';

            return (
              <div key={`${item.id}-${index}`} className="cart-item">
                <div className="item-image">
                  <img
                    src={image_url}
                    alt={item.name || item.title}
                    onError={(e) => {
                      e.target.src = 'https://via.placeholder.com/150x150'; // Image par défaut si l'image n'existe pas
                    }}
                    style={{ maxWidth: '150px', maxHeight: '150px' }}
                  />
                </div>
                <div className="item-details">
                  <p className="item-name">{item.name || item.title}</p>
                  <p className="item-price">
                    {item.offered ? 'Offert' : `${parseFloat(item.price).toFixed(2)} €`}
                  </p>
                </div>

                <div className="item-quantity">
                  <button
                    onClick={() => handleDecreaseQuantity(item.id)}
                    disabled={item.quantity <= 1}
                  >
                    -
                  </button>
                  <input type="number" value={item.quantity || 1} readOnly />
                  <button onClick={() => handleIncreaseQuantity(item.id)}>
                    +
                  </button>
                  <FaTrash
                    className="remove-icon"
                    onClick={() => handleRemoveItem(item.id)}
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    title="Supprimer"
                  />
                </div>
                <span className="item-total">
                  {parseFloat(item.price * (item.quantity || 1)).toFixed(2)} €
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="cart-right">
        <div className="cart-summary-widget">
          <div className="totals">
            <p>
              Sous-total ({cart.length} articles):{' '}
              {parseFloat(cart.reduce((total, item) => total + parseFloat(item.price) * (item.quantity || 1), 0)).toFixed(2)} €
            </p>
            <p>Livraison: 4.00 €</p>
            <h3>
              Total: {parseFloat(cart.reduce((total, item) => total + parseFloat(item.price) * (item.quantity || 1), 0) + 4.0).toFixed(2)} €
            </h3>
          </div>
          <button
            className="validate-button"
            onClick={handleSendOrderToOrderPage}
            disabled={isLoading}
          >
            {isLoading ? 'Commande en cours...' : 'Passer la commande'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartSummary;
