import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../scss/PaymentSuccess.scss';

const PaymentSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="payment-success">
      <h2>Paiement réussi</h2>
      <p>Merci pour votre commande !</p>
      <button onClick={() => navigate('/')}>Retour à l'accueil</button>
    </div>
  );
};

export default PaymentSuccess;
