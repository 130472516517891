import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../AdminScss/songManager.scss';

function SongManager() {
  const [songs, setSongs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [completedSongs, setCompletedSongs] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    music_style: '',
    custom_text: '',
    song_wish: '',
    include_clip: false,
    clip_option: '',
    price: '',
    uploaded_files: [],
    user_id: '',
    user_name: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // État pour le modal de suppression
  const [songToDelete, setSongToDelete] = useState(null); // Chanson à supprimer
  const navigate = useNavigate();

  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const token = getToken();

    if (!parsedUser || parsedUser.role !== 'admin' || !token) {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    } else {
      fetchSongs(token);
    }
  }, [navigate]);

  const fetchSongs = async (token) => {
    try {
      const response = await fetch('https://npm06.com/api/song/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des chansons');
      }
      const data = await response.json();
      setSongs(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des chansons:', error);
    }
  };

  // Fonction pour supprimer une chanson après confirmation
  const deleteSong = async () => {
    const token = getToken();
    try {
      const response = await fetch(`https://npm06.com/api/song/${songToDelete}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Erreur lors de la suppression de la chanson');
      }
      setSongs(songs.filter((song) => song.song_id !== songToDelete)); // Met à jour la liste des chansons
      setIsDeleteModalOpen(false); // Ferme le modal de confirmation
      setSongToDelete(null); // Réinitialise la chanson sélectionnée
    } catch (error) {
      console.error('Erreur lors de la suppression de la chanson:', error);
    }
  };

  const openDeleteModal = (songId) => {
    setSongToDelete(songId); // Définir la chanson à supprimer
    setIsDeleteModalOpen(true); // Ouvrir le modal de confirmation
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false); // Fermer le modal de confirmation
    setSongToDelete(null); // Réinitialiser la chanson sélectionnée
  };

  const openFileModal = (file) => {
    setSelectedFile(file);
    setIsFileModalOpen(true);
  };

  const closeFileModal = () => {
    setIsFileModalOpen(false);
    setSelectedFile(null);
  };

  const downloadFile = (fileName) => {
    const fileUrl = `https://npm06.com/uploads/${fileName}`;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getFileType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const videoExtensions = ['mp4', 'mov', 'avi', 'mkv'];
    const audioExtensions = ['mp3', 'wav', 'ogg'];

    if (imageExtensions.includes(extension)) {
      return 'image';
    } else if (videoExtensions.includes(extension)) {
      return 'video';
    } else if (audioExtensions.includes(extension)) {
      return 'audio';
    } else {
      return 'unknown';
    }
  };

  const openModal = (song = null) => {
    setFormData({
      title: song?.title || '',
      music_style: song?.music_style || '',
      custom_text: song?.custom_text || '',
      song_wish: song?.song_wish || '',
      include_clip: song?.include_clip || false,
      clip_option: song?.clip_option || '',
      price: song?.price || '',
      uploaded_files: song?.uploaded_files ? JSON.parse(song.uploaded_files) : [],
      user_id: song?.user_id || '',
      user_name: song?.user_name || '',
    });

    setIsModalOpen(true);
    setActiveTab(1);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const markAsCompleted = (songId) => {
    setCompletedSongs((prevState) => [...prevState, songId]);
  };

  // Fonction utilitaire pour formater correctement les dates
  const formatDate = (dateString) => {
    if (!dateString) return 'Date inconnue'; // Vérifie si la date existe
    const date = new Date(dateString);
    return isNaN(date) ? 'Date invalide' : date.toLocaleDateString('fr-FR'); // Formate la date en français
  };

  return (
    <div className="song-manager-container">
      <h1>Gestion des Chansons</h1>
      {errorMessage && <p>{errorMessage}</p>}
      <table className="table">
        <thead>
          <tr>
            <th>Titre</th>
            <th>Utilisateur</th>
            <th>Style Musical</th>
            <th>Prix</th>
            <th>Date de création</th>
            <th>Dernière mise à jour</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {songs.length > 0 ? (
            songs.map((song) => (
              <tr
                key={song.song_id}
                style={{
                  backgroundColor: completedSongs.includes(song.song_id) ? '#d4edda' : '',
                }}
              >
                <td>{song.title}</td>
                <td>{song.user_name} (ID: {song.user_id})</td>
                <td>{song.music_style}</td>
                <td>{song.price}</td>
                <td>{formatDate(song.created_at)}</td>
                <td>{formatDate(song.updated_at)}</td>
                <td>
                  <button onClick={() => openModal(song)}>Détails</button>
                  <button onClick={() => markAsCompleted(song.song_id)}>Effectué</button>
                  <button onClick={() => openDeleteModal(song.song_id)}>Supprimer</button> {/* Bouton Supprimer */}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">Aucune chanson disponible</td>
            </tr>
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <div className="tabs">
              <button
                className={activeTab === 1 ? 'active' : ''}
                onClick={() => setActiveTab(1)}
              >
                Détails
              </button>
              <button
                className={activeTab === 2 ? 'active' : ''}
                onClick={() => setActiveTab(2)}
              >
                Fichiers
              </button>
            </div>

            {activeTab === 1 && (
              <div className="tab-content">
                <p><strong>Titre :</strong> {formData.title}</p>
                <p><strong>Utilisateur :</strong> {formData.user_name} (ID: {formData.user_id})</p>
                <p><strong>Style Musical :</strong> {formData.music_style}</p>
                <p><strong>Texte personnalisé :</strong> {formData.custom_text || 'Aucun'}</p>
                <p><strong>Souhait pour la chanson :</strong> {formData.song_wish || 'Aucun'}</p>
                <p><strong>Clip inclus :</strong> {formData.include_clip ? 'Oui' : 'Non'}</p>
                {formData.include_clip && <p><strong>Option du clip :</strong> {formData.clip_option}</p>}
                <p><strong>Prix :</strong> {formData.price}€</p>
              </div>
            )}

            {activeTab === 2 && (
              <div className="tab-content">
                <h4>Fichiers associés</h4>
                {formData.uploaded_files.length > 0 ? (
                  formData.uploaded_files.map((file, index) => (
                    <div key={index}>
                      <a href="#!" onClick={() => openFileModal(file)}>
                        Voir le fichier {index + 1}
                      </a>
                      <button onClick={() => downloadFile(file)}>Télécharger</button>
                    </div>
                  ))
                ) : (
                  <p>Aucun fichier associé.</p>
                )}
              </div>
            )}

            <div className="modal-footer">
              <button type="button" onClick={closeModal}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}

      {isDeleteModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h4>Confirmer la suppression</h4>
            <p>Êtes-vous sûr de vouloir supprimer cette chanson ? Cette action est irréversible.</p>
            <div className="modal-footer">
              <button onClick={deleteSong}>Oui, supprimer</button>
              <button onClick={closeDeleteModal}>Annuler</button>
            </div>
          </div>
        </div>
      )}

      {isFileModalOpen && (
        <div className="file-modal">
          <div className="file-modal-content">
            <span className="close-button" onClick={closeFileModal}>
              &times;
            </span>
            <h4>Visualiser le fichier</h4>
            {selectedFile && (
              <div>
                {(() => {
                  const fileType = getFileType(selectedFile);
                  const fileUrl = `https://npm06.com/uploads/${selectedFile}`;
                  if (fileType === 'audio') {
                    return <audio controls src={fileUrl}></audio>;
                  } else if (fileType === 'video') {
                    return <video controls src={fileUrl} style={{ maxWidth: '100%' }}></video>;
                  } else if (fileType === 'image') {
                    return <img src={fileUrl} alt="Fichier associé" style={{ maxWidth: '100%' }} />;
                  } else {
                    return <p>Type de fichier non pris en charge.</p>;
                  }
                })()}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SongManager;
